import React, { FC } from 'react'
import { Link } from 'react-router-dom'

const Footer: FC = () => {
  return (
    <div className="main-footer">
      <span>&copy; 2023. Fracxn. All Rights Reserved.</span>
      <span>
        Created by:{' '}
        <Link to="" target="_blank">
          Fracxn
        </Link>
      </span>
    </div>
  )
}

export default Footer
