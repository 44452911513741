import * as AssetService from '../../services/internal/asset.service'
import { ActionType } from '../../interface/auth'
import { handleError } from '../../utils/error'
import { PropertyCreationParams } from '../../interface/asset'

export const assetActions = {
  CREATE_ASSET: 'CREATE_ASSET',
  LIST_ASSETS: 'LIST_ASSETS',
  FIND_ASSET: 'FIND_ASSET',
  UPDATE_ASSET: 'UPDATE_ASSET',
  DELETE_ASSET: 'DELETE_ASSET',
  ASSET_LOADING: 'ASSET_LOADING'
}

export const createAsset = async (
  payload: PropertyCreationParams
): Promise<ActionType> => {
  try {
    const response = await AssetService.create(payload)
    return {
      type: assetActions.CREATE_ASSET,
      payload: response.data
    }
  } catch (error: any) {
    throw new Error(handleError(error))
  }
}

export const listAssets = async (): Promise<ActionType> => {
  try {
    const response = await AssetService.list()
    return {
      type: assetActions.LIST_ASSETS,
      payload: response.data
    }
  } catch (error: any) {
    throw new Error(handleError(error))
  }
}

export const findAsset = async (id: string): Promise<ActionType> => {
  try {
    const response = await AssetService.find(id)
    return {
      type: assetActions.FIND_ASSET,
      payload: response.data
    }
  } catch (error: any) {
    throw new Error(handleError(error))
  }
}

export const updateAsset = async (
  id: string,
  payload: PropertyCreationParams
): Promise<ActionType> => {
  try {
    const response = await AssetService.update(id, payload)
    return {
      type: assetActions.UPDATE_ASSET,
      payload: response.data
    }
  } catch (error: any) {
    throw new Error(handleError(error))
  }
}

export const deleteAsset = async (id: string): Promise<ActionType> => {
  try {
    const response = await AssetService.deleteAsset(id)
    return {
      type: assetActions.DELETE_ASSET,
      payload: response.data
    }
  } catch (error: any) {
    throw new Error(handleError(error))
  }
}

export const uploadAssetImage = async (file: File): Promise<ActionType> => {
  try {
    const response = await AssetService.uploadAssetImage(file)
    return {
      type: assetActions.CREATE_ASSET,
      payload: response.data
    }
  } catch (error: any) {
    throw new Error(handleError(error))
  }
}

export const assetLoadingAction = (payload: boolean) => {
  return {
    type: assetActions.ASSET_LOADING,
    payload
  }
}
