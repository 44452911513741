import { IAuction } from '../../interface/auction'
import { ActionType } from '../../interface/auth'
import { IBid } from '../../interface/bids'
import { IPageMeta } from '../../interface/global'
import { auctionActions } from './auction.actions'

export interface AuctionType {
  auctions: IAuction[]
  auction?: IAuction
  bids: IBid[]
  loading: boolean
  meta?: IPageMeta
}

export const AUCTION_INITIAL_STATE: AuctionType = {
  auctions: [],
  auction: undefined,
  bids: [],
  loading: true,
  meta: undefined
}

export const auctionReducer = (
  state = AUCTION_INITIAL_STATE,
  action: ActionType
) => {
  switch (action.type) {
    case auctionActions.LIST_AUCTIONS:
      return {
        ...state,
        auctions: action.payload,
        loading: false
      }
    case auctionActions.FIND_AUCTION:
      return {
        ...state,
        auction: action.payload,
        loading: false
      }
    case auctionActions.LIST_AUCTION_BIDS:
      return {
        ...state,
        bids: action.payload,
        loading: false
      }
    case auctionActions.AUCTION_LOADING:
      return {
        ...state,
        loading: action.payload
      }

    default:
      throw new Error(`Unknown action type: ${action.type}`)
  }
}
