import React, { useContext, useEffect, useState } from 'react'

import PageTitle from '../../../components/PageTitle'
import Header from '../../../layouts/Header'
import { Button, Card, Col, ListGroup, Nav, Row } from 'react-bootstrap'
import { Link, useParams } from 'react-router-dom'
import {
  UserDispatchContext,
  UserStateContext
} from '../../../store/User/user.provider'
import { toast } from 'react-toastify'
import {
  blockUser,
  findUser,
  listUserBids,
  listUserDeposits,
  listUserInvestments,
  listUserWithdraws,
  unblockUser,
  userLoadingAction
} from '../../../store/User/user.actions'
import {
  ITransaction,
  InvestmentStatusEnum,
  TxnStatusEnum
} from '../../../interface/transaction'
import moment from 'moment'
import {
  BidStatusEnum,
  IBid,
  IInvestmentResponse
} from '../../../interface/bids'
import { TRANSACTIONS } from '../../../routing/navigation'
import { TableColumn } from 'react-data-table-component'
import CustomDataTable from '../../../components/DataTable'
import userAvatar from '../../../assets/img/img1.jpg'
import { AuthStateContext } from '../../../store/Auth/auth.provider'
import Swal from 'sweetalert2'

const emptyState = (state: string) => {
  return (
    <div className="d-flex justify-content-center mt-4">
      <h5 className="opacity-50">No {state}</h5>
    </div>
  )
}

const UserDetails: React.FC = () => {
  const currentSkin = localStorage.getItem('skin-mode') ? 'dark' : ''

  const [skin, setSkin] = useState(currentSkin)

  const [activeTab, setActiveTab] = useState('deposits')

  const handleTabChange = (tab: string) => {
    setActiveTab(tab)
  }

  const columns: TableColumn<ITransaction>[] = [
    {
      name: 'Amount',
      cell: (row) => `${row.currency} ${row.amount}`
    },
    {
      name: 'Type',
      cell: (row) => row.type
    },
    {
      name: 'Status',
      cell: (row) => (
        <p
          className={`d-block fs-xs text-${
            row.status === TxnStatusEnum.SUCCESS
              ? 'success'
              : row.status === TxnStatusEnum.PENDING
              ? 'warning'
              : 'danger'
          }`}
        >
          {row.status}
        </p>
      )
    },
    {
      name: 'Source',
      cell: (row) => row.source
    },
    {
      name: 'Created At',
      cell: (row) => moment(row.createdAt).format('YYYY-MM-DD')
    }
  ]

  const investmentColumns: TableColumn<IInvestmentResponse>[] = [
    {
      name: 'Amount',
      cell: (row) => `${row.asset.currency} ${row.amount}`
    },
    {
      name: 'Asset',
      cell: (row) => row.asset.title
    },
    {
      name: 'Status',
      cell: (row) => (
        <p
          className={`d-block fs-xs text-${
            row.status === TxnStatusEnum.SUCCESS
              ? 'success'
              : row.status === TxnStatusEnum.PENDING
              ? 'warning'
              : 'danger'
          }`}
        >
          {row.status}
        </p>
      )
    },
    {
      name: 'Number of bids',
      cell: (row) => <span>{row.bidCount.toString()} </span>
    },
    {
      name: 'Unlocked At',
      cell: (row) => moment(row.unlockAt).format('YYYY-MM-DD')
    },
    {
      name: 'Created At',
      cell: (row) => moment(row.createdAt).format('YYYY-MM-DD')
    }
  ]

  const bidsColumns: TableColumn<IBid>[] = [
    {
      name: 'Amount',
      cell: (row) => `${row.asset?.currency} ${row.amount}`
    },
    {
      name: 'Asset',
      cell: (row) => row.asset.title
    },
    {
      name: 'Status',
      cell: (row) => (
        <p
          className={`d-block fs-xs text-${
            row.status === BidStatusEnum.OPEN
              ? 'success'
              : row.status === BidStatusEnum.REFUNDED
              ? 'warning'
              : 'danger'
          }`}
        >
          {row.status}
        </p>
      )
    },
    {
      name: 'Payment Id',
      cell: (row) => row.payment?.debit ?? row.payment?.credit
    },
    {
      name: 'Created At',
      cell: (row) => moment(row.createdAt).format('YYYY-MM-DD')
    }
  ]

  const switchSkin = (skin: any): void => {
    if (skin === 'dark') {
      const btnWhite = document.getElementsByClassName('btn-white')
      // @ts-expect-error
      for (const btn of btnWhite) {
        btn.classList.add('btn-outline-primary')
        btn.classList.remove('btn-white')
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName(
        'btn-outline-primary'
      )
      // @ts-expect-error
      for (const btn of btnOutlinePrimary) {
        btn.classList.remove('btn-outline-primary')
        btn.classList.add('btn-white')
      }
    }
  }

  const { id } = useParams<{ id: string }>()

  const { investments, bids, withdrawals, deposits, loading, user } =
    useContext(UserStateContext)

  const dispatch = useContext(UserDispatchContext)

  const handleBlockUser = async (id: string): Promise<void> => {
    try {
      Swal.fire({
        title: 'Are you sure you want to block this user?',
        showCancelButton: true,
        confirmButtonText: 'Continue'
      }).then(async (result) => {
        if (result.isConfirmed) {
          Swal.fire('User blocked!', '', 'success')
          dispatch(await blockUser(id))
          handleFindUser(id)
        }
      })
    } catch (error) {
      toast.error('Something went wrong')
    }
  }

  const handleUnblockUser = async (id: string) => {
    try {
      dispatch(await unblockUser(id))
      toast.success('User unblocked')
      handleFindUser(id)
    } catch (error) {
      toast.error('Something went wrong')
    }
  }

  const onBlockUserClick = (id: string): void => {
    handleBlockUser(id)
  }

  const onUnblockUserClick = (id: string): void => {
    handleUnblockUser(id)
  }
  const handleFindUser = async (id: string) => {
    try {
      dispatch(await findUser(id))
    } catch (error: any) {
      toast.error(error)
    }
  }

  const handleListUserInvestments = async (id: string) => {
    try {
      dispatch(userLoadingAction(true))
      dispatch(await listUserInvestments(id))
    } catch (error: any) {
      toast.error(error)
    }
  }

  const handleListUserBids = async (id: string) => {
    try {
      dispatch(userLoadingAction(true))
      dispatch(await listUserBids(id))
    } catch (error: any) {
      toast.error(error)
    }
  }

  const handleListUserDeposits = async (id: string) => {
    try {
      dispatch(userLoadingAction(true))
      dispatch(await listUserDeposits(id))
    } catch (error: any) {
      toast.error(error)
    }
  }

  const handleListUserWithdrawals = async (id: string) => {
    try {
      dispatch(userLoadingAction(true))
      dispatch(await listUserWithdraws(id))
    } catch (error: any) {
      toast.error(error)
    }
  }

  const DepositsTabContent: React.FC = () => {
    return (
      <Card className="card-one">
        <Card.Header>
          <Nav className="nav-icon nav-icon-sm ms-auto">
            <Nav.Link href="">
              <i className="ri-refresh-line"></i>
            </Nav.Link>
            <Nav.Link href="">
              <i className="ri-more-2-fill"></i>
            </Nav.Link>
          </Nav>
        </Card.Header>
        <Card.Body className="p-0">
          <CustomDataTable
            columns={columns}
            data={deposits}
            noDataComponent={emptyState('Deposits')}
            progressPending={loading}
            persistTableHead
          />
        </Card.Body>
        <Card.Footer className="d-flex justify-content-center">
          <Link to={`/${TRANSACTIONS}`} className="fs-sm">
            Manage Transactions
          </Link>
        </Card.Footer>
      </Card>
    )
  }

  const InvestmentsTabContent: React.FC = () => {
    return (
      <div className="investments-tab-content">
        <div className="investments-list">
          <CustomDataTable
            columns={investmentColumns}
            data={investments}
            noDataComponent={emptyState('Investments')}
            progressPending={loading}
            persistTableHead
          />
        </div>
      </div>
    )
  }

  const BidsTabContent: React.FC = () => {
    return (
      <div className="investments-tab-content">
        <div className="investments-list">
          <CustomDataTable
            columns={bidsColumns}
            data={bids}
            noDataComponent={emptyState('Bids')}
            progressPending={loading}
            persistTableHead
          />
        </div>
      </div>
    )
  }

  const WithdrawalsTabContent: React.FC = () => {
    return (
      <Card className="card-one">
        <Card.Body className="p-0">
          <CustomDataTable
            columns={columns}
            data={withdrawals}
            noDataComponent={emptyState('Withdrawals')}
            progressPending={loading}
            persistTableHead
          />
        </Card.Body>
        <Card.Footer className="d-flex justify-content-center">
          <Link to={`/${TRANSACTIONS}`} className="fs-sm">
            Manage Transactions
          </Link>
        </Card.Footer>
      </Card>
    )
  }

  useEffect(() => {
    if (id) {
      handleFindUser(id)
      handleListUserInvestments(id)
      handleListUserBids(id)
      handleListUserDeposits(id)
      handleListUserWithdrawals(id)
    }
  }, [id])

  switchSkin(skin)
  useEffect(() => {
    switchSkin(skin)
  }, [skin])
  return (
    <div className="main main-app p-3 p-lg-4">
      <Header onSkin={setSkin} />
      <PageTitle title="User Detail" />
      <div className="mb-2">
        <Row>
          <Col xl>
            <div className="media-profile mb-5">
              <div className="media-img mb-3 mb-sm-0">
                <img src={userAvatar} className="img-fluid" alt="..." />
              </div>

              <div className="media-body">
                <h5 className="media-name">{user?.name}</h5>
                <p className="d-flex gap-2 mb-2">
                  {' '}
                  {user?.email}{' '}
                  <strong
                    style={{
                      color: user?.status === 'success' ? 'green' : 'red'
                    }}
                    className="mx-2"
                  >
                    {user?.status}
                  </strong>
                </p>

                <span className="me-3">
                  <strong>Mobile:</strong> {user?.phone ?? 'NAN'}.
                </span>
                <span>
                  <strong>Created At:</strong>{' '}
                  {moment(user?.createdAt).format('YYYY MMMM D')}
                </span>

                <div className="mt-2">
                  {user?.status === 'success' ? (
                    <Button
                      className="me-2 text-nowrap"
                      variant="danger"
                      size="sm"
                      onClick={() => onBlockUserClick(user?._id)}
                    >
                      Block
                    </Button>
                  ) : (
                    user && (
                      <Button
                        className="me-2 text-nowrap"
                        variant="primary"
                        size="sm"
                        onClick={() => onUnblockUserClick(user?._id)}
                      >
                        Unblock
                      </Button>
                    )
                  )}
                </div>
              </div>
            </div>

            <Nav className="nav-line mb-4">
              <Nav.Link
                href="#deposits"
                className={activeTab === 'deposits' ? 'active' : ''}
                onClick={() => handleTabChange('deposits')}
              >
                Deposits
              </Nav.Link>
              <Nav.Link
                href="#withdrawals"
                className={activeTab === 'withdrawals' ? 'active' : ''}
                onClick={() => handleTabChange('withdrawals')}
              >
                Withdrawals
              </Nav.Link>
              <Nav.Link
                href="#investments"
                className={activeTab === 'investments' ? 'active' : ''}
                onClick={() => handleTabChange('investments')}
              >
                Investments
              </Nav.Link>
              <Nav.Link
                href="#bids"
                className={activeTab === 'bids' ? 'active' : ''}
                onClick={() => handleTabChange('bids')}
              >
                Bids
              </Nav.Link>
            </Nav>

            {/* Conditional rendering based on activeTab */}
            {activeTab === 'deposits' && <DepositsTabContent />}
            {activeTab === 'investments' && <InvestmentsTabContent />}
            {activeTab === 'bids' && <BidsTabContent />}
            {activeTab === 'withdrawals' && <WithdrawalsTabContent />}
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default UserDetails
