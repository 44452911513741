import * as AuthService from '../../services/internal/auth.service'
import { ActionType, ILogin } from '../../interface/auth'
import { handleError } from '../../utils/error'

export const authActions = {
  LOGIN: 'LOGIN',
  REGISTER: 'REGISTER',
  AUTH_LOADING: 'AUTH_LOADING',
  AUTH_ERROR: 'AUTH_ERROR',
  AUTH_USER: 'AUTH_USER',
  GLOBAL_ERROR: 'GLOBAL_ERROR',
  GLOBAL_SUCCESS: 'GLOBAL_SUCCESS',
  LOGOUT: 'LOGOUT',
}

export const loginAction = async (payload: ILogin): Promise<ActionType> => {
  try {
    const { email, password } = payload
    const newLogin = await AuthService.login({ email, password })
    return {
      type: authActions.LOGIN,
      payload: { ...newLogin.data, email },
    }
  } catch (error: any) {
    throw new Error(handleError(error))
  }
}

export const authLoadingAction = (payload: boolean) => {
  return {
    type: authActions.AUTH_LOADING,
    payload,
  }
}

export const getAuthUserAction = async (): Promise<ActionType> => {
  try {
    const userResponse = await AuthService.getAuthUser()
    return {
      type: authActions.AUTH_USER,
      payload: userResponse.data.data,
    }
  } catch (error: any) {
    throw new Error(error.response?.message || 'fatal error')
  }
}
