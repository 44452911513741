import { AuthModel } from '../interface/auth'
import { IUser } from '../interface/user'

const AUTH_LOCAL_STORAGE_KEY = 'fracxn-auth'
const USER_LOCAL_STORAGE_KEY = 'fracxn-user'

export const setItem = (key: string, val: string) => {
  window.localStorage.setItem(key, val)
}

export const getItem = (item: string) => {
  return window.localStorage.getItem(item)
}

export const removeItem = (...items: string[]) => {
  items.map((item) => window.localStorage.removeItem(item))
}

export const clearStorage = () => window.localStorage.clear()

export const getAuth = (): AuthModel | undefined => {
  try {
    const authString: string | null = getItem(AUTH_LOCAL_STORAGE_KEY)
    const auth: AuthModel | undefined = authString
      ? (JSON.parse(authString) as AuthModel)
      : undefined
    if (!auth) {
      return
    }

    const dateExpireSeconds = new Date(auth.expiresAt ?? 0)
    const expiresAt = Math.floor(dateExpireSeconds.getTime() / 1000)
    if (expiresAt < Math.floor(Date.now() / 1000)) {
      removeAuth()
      location.reload()
      return
    }

    return auth
  } catch (error) {
    console.error('AUTH LOCAL STORAGE PARSE ERROR', error)
  }
}

export const setAuth = (auth: AuthModel) => {
  try {
    const authString = JSON.stringify(auth)
    setItem(AUTH_LOCAL_STORAGE_KEY, authString)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE SAVE ERROR', error)
  }
}

export const removeAuth = () => {
  removeItem(AUTH_LOCAL_STORAGE_KEY)
}

export const getUser = (): IUser | undefined => {
  try {
    const userString: string | null = getItem(USER_LOCAL_STORAGE_KEY)
    const user: IUser | undefined = userString
      ? (JSON.parse(userString) as IUser)
      : undefined
    if (!user) {
      return
    }

    return user
  } catch (error) {
    console.error('USER LOCAL STORAGE PARSE ERROR', error)
  }
}

export const setUser = (user: IUser) => {
  try {
    const userString = JSON.stringify(user)
    setItem(USER_LOCAL_STORAGE_KEY, userString)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE SAVE ERROR', error)
  }
}
