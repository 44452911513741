import React, { useContext, useEffect, useState } from 'react'
import Table from '../../../components/Table'

import PageTitle from '../../../components/PageTitle'
import Header from '../../../layouts/Header'
import { Button } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { USER_DETAILS } from '../../../routing/navigation'
import { insertPathParam } from '../../../utils/route'
import {
  UserDispatchContext,
  UserStateContext
} from '../../../store/User/user.provider'
import {
  blockUser,
  listUsers,
  unblockUser,
  userLoadingAction
} from '../../../store/User/user.actions'
import Swal from 'sweetalert2'
import { toast } from 'react-toastify'
import CustomDataTable from '../../../components/DataTable'
import { IUser } from '../../../interface/user'
import { TableColumn } from 'react-data-table-component'

const emptyState = () => {
  return (
    <div className="d-flex justify-content-center mt-4">
      <h5 className="opacity-50">No User found.</h5>
    </div>
  )
}
const UserManagement: React.FC = () => {
  const navigate = useNavigate()
  const { users, loading } = useContext(UserStateContext)

  const dispatch = useContext(UserDispatchContext)

  const currentSkin = localStorage.getItem('skin-mode') ? 'dark' : ''

  const [skin, setSkin] = useState(currentSkin)

  const view = (id: string): void => {
    const url = insertPathParam(`/${USER_DETAILS}`, { id })
    navigate(url)
  }

  const handleBlockUser = async (id: string): Promise<void> => {
    try {
      Swal.fire({
        title: 'Are you sure you want to block this user?',
        showCancelButton: true,
        confirmButtonText: 'Continue'
      }).then(async (result) => {
        if (result.isConfirmed) {
          Swal.fire('User blocked!', '', 'success')
          dispatch(await blockUser(id))
          getUsers()
        }
      })
    } catch (error) {
      toast.error('Something went wrong')
    }
  }

  const handleUnblockUser = async (id: string) => {
    try {
      dispatch(await unblockUser(id))
      toast.success('User unblocked')
      getUsers()
    } catch (error) {
      toast.error('Something went wrong')
    }
  }

  const onBlockUserClick = (id: string): void => {
    handleBlockUser(id)
  }

  const onUnblockUserClick = (id: string): void => {
    handleUnblockUser(id)
  }

  const renderActionsCell = (row: IUser) => (
    <div className="d-flex">
      {row.status === 'success' ? (
        <Button
          className="me-2 text-nowrap"
          variant="danger"
          size="sm"
          onClick={() => onBlockUserClick(row._id)}
        >
          Block
        </Button>
      ) : (
        <Button
          className="me-2 text-nowrap"
          variant="primary"
          size="sm"
          onClick={() => onUnblockUserClick(row._id)}
        >
          Unblock
        </Button>
      )}
      <Button
        className="me-2 text-nowrap"
        variant="link"
        size="sm"
        onClick={() => view(row._id)}
      >
        View
      </Button>
    </div>
  )

  const renderStatusCell = (row: IUser) => (
    <p
      style={{ color: row.status === 'success' ? 'green' : 'red' }}
      className="d-block fs-xs"
    >
      {row.status}
    </p>
  )

  const columns: TableColumn<IUser>[] = [
    {
      name: 'Email',
      cell: (row) => row.email,
      sortable: true,
      grow: 2
    },
    {
      name: 'Name',
      cell: (row) => row.name ?? '--',
      sortable: true
    },
    {
      name: 'Phone',
      cell: (row) => row.phone ?? '--',
      sortable: true
    },
    {
      name: 'KYC Level',
      cell: (row) => row.kycLevel,
      sortable: true
    },
    {
      name: 'Status',
      cell: renderStatusCell,
      sortable: true
    },
    {
      name: 'Actions',
      cell: renderActionsCell,
      grow: 2
    }
  ]

  const switchSkin = (skin: any): void => {
    if (skin === 'dark') {
      const btnWhite = document.getElementsByClassName('btn-white')
      // @ts-expect-error
      for (const btn of btnWhite) {
        btn.classList.add('btn-outline-primary')
        btn.classList.remove('btn-white')
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName(
        'btn-outline-primary'
      )
      // @ts-expect-error
      for (const btn of btnOutlinePrimary) {
        btn.classList.remove('btn-outline-primary')
        btn.classList.add('btn-white')
      }
    }
  }

  const getUsers = async () => {
    dispatch(userLoadingAction(true))
    dispatch(await listUsers())
  }

  switchSkin(skin)
  useEffect(() => {
    switchSkin(skin)
  }, [skin])

  useEffect(() => {
    getUsers()
  }, [])

  return (
    <div className="main main-app p-3 p-lg-4">
      <Header onSkin={setSkin} />
      <PageTitle title="Users" />
      <div className="mb-5">
        <CustomDataTable
          columns={columns}
          data={users}
          noDataComponent={emptyState()}
          progressPending={loading}
          persistTableHead
        />
      </div>
    </div>
  )
}

export default UserManagement
