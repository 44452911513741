import React, { createContext, Dispatch, useReducer } from 'react'
import { AUCTION_INITIAL_STATE, auctionReducer } from './auction.reducer'
import { ActionType } from '../../interface/auth'

export const AuctionStateContext = createContext({ ...AUCTION_INITIAL_STATE })
export const AuctionDispatchContext = createContext<Dispatch<ActionType>>(
  () => {}
)

interface Props {
  children: React.ReactNode
}

export const AuctionProvider: React.FC<Props> = ({ children }) => {
  const [state, dispatch] = useReducer(auctionReducer, AUCTION_INITIAL_STATE)

  return (
    <AuctionDispatchContext.Provider value={dispatch}>
      <AuctionStateContext.Provider value={state}>
        {children}
      </AuctionStateContext.Provider>
    </AuctionDispatchContext.Provider>
  )
}
