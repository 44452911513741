import { IError } from '../interface/error'

export const getError = async (data: Response): Promise<Error> => {
  const status = data.status
  if (data.text) {
    const dd = await data.text()

    if (dd) {
      const error: IError = typeof dd === 'string' ? JSON.parse(dd) : dd
      if (error.auth === false) {
        throw {
          message: error.message,
          status,
        }
      } else {
        throw {
          message: error.message,
          status,
        }
      }
    } else {
      throw {
        message: 'An error occurred, please try again later.',
      }
    }
  } else {
    const newError: unknown = data
    const error = newError as IError
    throw {
      message: error.message || 'An error occurred, please try again later.',
      status,
    }
  }
}
export const handleError = (error: any): string => {
  if (error.response?.data?.message) {
    return error.response.data.message
  }

  return error.message || 'An error occurred, please try again later.'
}
