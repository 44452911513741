import React, { FC } from 'react'
import { Button, Card, Col, Container, Form, Row } from 'react-bootstrap'
// import { Link } from 'react-router-dom'

import pageSvg from '../../assets/svg/forgot_password.svg'

 const ForgotPassword: FC = () => {
  return (
    <div className="page-auth">

      <div className="content">
        <Container>
          <Card className="card-auth">
            <Card.Body className="text-center">
              <div className="mb-5">
                <object type="image/svg+xml" data={pageSvg} className="w-50" aria-label="svg image"></object>
              </div>
              <Card.Title>Reset your password</Card.Title>
              <Card.Text className="mb-5">Enter your username or email address and we will send you a link to reset your password.</Card.Text>

              <Row className="g-2">
                <Col sm="8"><Form.Control type="text" placeholder="Enter email address" /></Col>
                <Col sm="4"><Button variant="primary" href="">Reset</Button></Col>
              </Row>
            </Card.Body>
          </Card>
        </Container>
      </div>
    </div>
  )
}

export default ForgotPassword
