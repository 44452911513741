import { IProperty } from '../../interface/asset'
import { ActionType } from '../../interface/auth'
import { IPageMeta } from '../../interface/global'
import { assetActions } from './asset.actions'

export interface AssetType {
  assets: IProperty[]
  asset?: IProperty
  loading: boolean
  meta?: IPageMeta
}

export const ASSET_INITIAL_STATE: AssetType = {
  assets: [],
  asset: undefined,
  loading: true,
  meta: undefined
}

export const assetReducer = (
  state = ASSET_INITIAL_STATE,
  action: ActionType
) => {
  switch (action.type) {
    case assetActions.LIST_ASSETS:
      return {
        ...state,
        assets: action.payload,
        loading: false
      }
    case assetActions.FIND_ASSET:
      return {
        ...state,
        asset: action.payload,
        loading: false
      }
    case assetActions.CREATE_ASSET:
      return {
        ...state,
        loading: false
      }
    case assetActions.ASSET_LOADING:
      return {
        ...state,
        loading: action.payload
      }

    default:
      throw new Error(`Unknown action type: ${action.type}`)
  }
}
