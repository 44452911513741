import React, { useContext, useEffect, useRef, useState } from 'react'
// import Button from '../../components/Button'
import Card from '../../../components/Card'
import { Icon } from '../../../components/Icons'
import { useNavigate, useParams } from 'react-router-dom'
import PropertyPhotoList from '../../../components/AuctionPhotoList'
import PageTitle from '../../../components/PageTitle'
import Header from '../../../layouts/Header'
import {
  AuctionDispatchContext,
  AuctionStateContext
} from '../../../store/Auction/auction.provider'
import {
  auctionLoading,
  findAuction,
  getAuctionBids
} from '../../../store/Auction/auction.actions'
import { toast } from 'react-toastify'
import { AuctionStatusEnum } from '../../../interface/auction'
import moment from 'moment'
import { BidStatusEnum, IBid } from '../../../interface/bids'
import { ASSETS } from '../../../routing/navigation'
import { Spinner } from 'react-bootstrap'
import CustomDataTable from '../../../components/DataTable'
import { TableColumn } from 'react-data-table-component'

const bidsColumns: TableColumn<IBid>[] = [
  {
    name: 'Amount',
    cell: (row) => `${row.currency} ${row.amount}`
  },
  {
    name: 'Status',
    cell: (row) => (
      <p
        className={`d-block m-0 fs-xs text-${
          row.status === BidStatusEnum.OPEN
            ? 'success'
            : row.status === BidStatusEnum.REFUNDED
            ? 'warning'
            : 'danger'
        }`}
      >
        {row.status}
      </p>
    )
  },
  {
    name: 'Payment Id',
    cell: (row) => row.payment?.debit ?? row.payment?.credit
  },
  {
    name: 'Created At',
    cell: (row) => moment(row.createdAt).format('YYYY-MM-DD')
  }
]

const emptyState = () => {
  return (
    <div className="d-flex justify-content-center mt-4">
      <h5 className="opacity-50">No available bids.</h5>
    </div>
  )
}

const AuctionDetails: React.FC = () => {
  const route = useNavigate()
  const { id } = useParams<{ id: string }>()

  const { auction, bids, loading } = useContext(AuctionStateContext)

  const dispatch = useContext(AuctionDispatchContext)

  const handleFindAuction = async (id: string) => {
    try {
      dispatch(await findAuction(id))
    } catch (error: any) {
      toast.error(error)
    }
  }

  const handleGetAuctionBids = async (id: string) => {
    try {
      dispatch(await getAuctionBids(id))
    } catch (error: any) {
      toast.error(error)
    }
  }

  useEffect(() => {
    if (id) {
      dispatch(auctionLoading(true))
      handleFindAuction(id)
      handleGetAuctionBids(id)
    }
  }, [id])

  const isMounted = useRef(false)

  useEffect(() => {
    isMounted.current = true
    // getAuction()
    window.scrollTo(0, 0)
    return () => {
      isMounted.current = false
    }
  }, [id])

  return (
    <div className="main main-app p-3 p-lg-4">
      <Header />
      {loading ? (
        <div className="d-flex justify-content-center">
          <Spinner animation="grow" />
        </div>
      ) : (
        auction && (
          <>
            <PageTitle title={`${auction?.asset.title}` || 'Auction details'} />
            <div
              className="Property-details pb-5"
              style={!auction ? { height: '100vh' } : {}}
            >
              <div className="container">
                <PropertyPhotoList images={auction.asset.image} />

                <div className="row">
                  <div className="Save-photo col-12 d-flex justify-content-end mt-4">
                    {/* <div className="me-4">
                          <Button
                            type="outline"
                            onClick={handleFavoriteClick}
                            size="small"
                            label={
                              <>
                                <Icon
                                  name="love"
                                  color={auction.favorite ? '#ff0066' : 'transparent'}
                                />
                                <span className="p ms-2">
                                  {auction.favorite ? 'Saved' : 'Save'}
                                </span>
                              </>
                            }
                          />
                        </div> */}
                  </div>
                </div>
                <section className="Detail-section">
                  <h2
                    className="text-primary"
                    style={{ cursor: 'pointer' }}
                    onClick={() => route(`/${ASSETS}/${auction.asset._id}`)}
                  >
                    {auction.asset.title || auction.asset.type}
                  </h2>
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      {auction.asset.specification.map((spec: any) => (
                        <li
                          key={spec._id}
                          className="breadcrumb-item active"
                          aria-current="page"
                        >
                          {spec.value} {spec.name}
                        </li>
                      ))}

                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        {auction.asset.area}
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        {auction.asset.locationInfo.state}
                      </li>
                    </ol>
                  </nav>

                  <div className="row">
                    <div className="col-lg-8">
                      <div className="mb-5">
                        <Card>
                          <div className="card-body d-flex">
                            <div className="align-self-center me-4">
                              <Icon name="dubai-large" />
                            </div>
                            <div>
                              <h3>
                                {auction.asset.locationInfo.country},{' '}
                                {auction.asset.locationInfo.state}
                              </h3>
                              <small>{auction.asset.description}</small>
                            </div>
                          </div>
                          {auction.asset.status === 'rent' && (
                            <div className="card-body d-flex">
                              <div className="align-self-center me-4">
                                <Icon name="rented" />
                              </div>
                              <div>
                                <h3>{auction.asset.status}</h3>
                              </div>
                            </div>
                          )}
                          <div className="card-body d-flex">
                            <div className="align-self-center me-4">
                              <Icon name="bank" />
                            </div>
                            <div>
                              <h3>
                                Current rent is {auction.asset.currency}{' '}
                                {Number(auction.asset.rentPerMonth)} per month{' '}
                              </h3>
                              <small>
                                Distributed{' '}
                                {auction.asset.distributionFrequency?.toLowerCase()}{' '}
                                among the investors after deducting the charges
                                and fees
                              </small>
                            </div>
                          </div>
                        </Card>
                      </div>
                    </div>

                    <div className="col-lg-4">
                      <div className="mb-5">
                        <Card>
                          <div className="card-body d-flex">
                            <div>
                              <h5>
                                {'Minimum Bid: '}
                                {auction.minimumBid}
                              </h5>
                              <small>Maximum Bid: {auction.maximumBid}</small>
                            </div>
                          </div>
                          {auction.status === AuctionStatusEnum.ACTIVE ? (
                            <div className="card-body d-flex">
                              <div className="align-self-center me-4">
                                <Icon name="info" />
                              </div>
                              <div>
                                <h6>{auction.status} </h6>
                                <small>
                                  Created at:{' '}
                                  {moment(auction.createdAt).format(
                                    'Do MMM, YYYY'
                                  )}
                                </small>
                              </div>
                            </div>
                          ) : (
                            <div className="card-body d-flex">
                              <div className="align-self-center me-4">
                                <Icon name="close" />
                              </div>
                              <div>
                                <h5>{auction.status}</h5>
                                <small>
                                  Created at:{' '}
                                  {moment(auction.createdAt).format(
                                    'Do MMM, YYYY'
                                  )}
                                </small>
                              </div>
                            </div>
                          )}
                          <div className="card-body d-flex">
                            <div className="align-self-center me-4">
                              <Icon name="coins" />
                            </div>
                            <div>
                              <h5>Total Bid: {Number(auction.totalBid)}</h5>
                              <small>
                                Days Left:{' '}
                                {moment(auction.endTime).diff(
                                  moment(auction.startTime),
                                  'days'
                                )}
                              </small>
                            </div>
                          </div>
                        </Card>
                      </div>
                    </div>
                  </div>
                </section>

                <section className="Detail-section">
                  <h2>{'Bids'}</h2>

                  <div className="row">
                    <div className="col-lg-12">
                      <div className="mb-3">
                        <CustomDataTable
                          columns={bidsColumns}
                          data={bids}
                          noDataComponent={emptyState()}
                          progressPending={loading}
                          persistTableHead
                        />
                        {/* <Card>
                          {bids.map((bid) => (
                            <div className="card-body d-flex" key={bid._id}>
                              <div className="align-self-center me-4">
                                <Icon name="coins" />
                              </div>
                              <div>
                                <h6>
                                  {bid.currency} {bid.amount}
                                </h6>
                                <small>
                                  <span
                                    className={`d-block fs-xs text-${
                                      bid.status === BidStatusEnum.OPEN
                                        ? 'success'
                                        : bid.status === BidStatusEnum.CLOSE
                                        ? 'danger'
                                        : 'warning'
                                    }`}
                                  >
                                    {bid.status}
                                  </span>
                                </small>
                              </div>
                            </div>
                          ))}
                        </Card> */}
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </>
        )
      )}
    </div>
  )
}

export default AuctionDetails
