/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC, useContext, useEffect, useState } from 'react'
import 'chart.js/auto'
import { Button, Card, Col, Nav, ProgressBar, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Footer from '../../layouts/Footer'
import Header from '../../layouts/Header'
import { shortenNumber } from '../../utils/number'
import { Doughnut } from 'react-chartjs-2'
import ReactApexChart from 'react-apexcharts'
import { UserStateContext } from '../../store/User/user.provider'
import { AssetStateContext } from '../../store/Assets/asset.provider'
import { getDashboardStats } from '../../services/internal/transaction.service'

// import { dp3 } from '../data/DashboardData'
// import ReactApexChart from 'react-apexcharts'

const Dashboard: FC = () => {
  const currentSkin = localStorage.getItem('skin-mode') ? 'dark' : ''
  const [skin, setSkin] = useState(currentSkin)

  const initialDashboardStates = {
    auctions: 0,
    users: 0,
    deposits: 0,
    withdrawRequests: 0,
    assets: 0,
    investments: 0
  }
  const [dashboardData, setDashboardData] = useState(initialDashboardStates)

  const getDashboardData = async () => {
    try {
      const { data } = await getDashboardStats()
      setDashboardData((prevData) => ({
        ...prevData,
        ...data
      }))
    } catch (error) {
      //
    }
  }

  const switchSkin = (skin: any): void => {
    if (skin === 'dark') {
      const btnWhite = document.getElementsByClassName('btn-white')
      // @ts-expect-error
      for (const btn of btnWhite) {
        btn.classList.add('btn-outline-primary')
        btn.classList.remove('btn-white')
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName(
        'btn-outline-primary'
      )
      // @ts-expect-error
      for (const btn of btnOutlinePrimary) {
        btn.classList.remove('btn-outline-primary')
        btn.classList.add('btn-white')
      }
    }
  }

  switchSkin(skin)
  useEffect(() => {
    switchSkin(skin)
  }, [skin])

  useEffect(() => {
    getDashboardData()
  }, [])

  // Asset class
  const dataPie = {
    labels: ['Real estate', 'Music', 'Commodity', 'Arts'],
    datasets: [
      {
        data: [40, 25, 20, 15],
        backgroundColor: ['#506fd9', '#85b6ff', '#50586d', '#b9bdc9']
      }
    ]
  }

  const optionPie = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        display: false
      }
    },
    animation: {
      animateScale: true,
      animateRotate: true
    }
  }

  // Column Chart
  const seriesColumn = [
    {
      name: 'Open',
      data: [44, 55, 57, 56, 61, 58, 63, 60, 66, 56, 90, 10]
    },
    {
      name: 'Close',
      data: [76, 85, 101, 98, 87, 105, 91, 114, 94, 56, 90, 10]
    }
    //   {
    //     name: 'Free Cash Flow',
    //     data: [35, 41, 36, 26, 45, 48, 52, 53, 41, 56, 90, 10]
    //   }
  ]

  const optionColumn = {
    chart: {
      toolbar: {
        show: false
      }
    },
    colors: ['#506fd9', '#85b6ff', '#a8b5c3'],
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',
        endingShape: 'rounded'
      }
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent']
    },
    xaxis: {
      categories: [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec'
      ]
    },
    fill: {
      opacity: 1
    },
    tooltip: {
      y: {
        formatter: function (val: any) {
          // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
          return '$ ' + val + ' thousands'
        }
      }
    }
  }

  const seriesTsColumn = [
    {
      name: 'Debit',
      data: [44, 55, 57, 56, 61, 58, 63, 60, 66, 56, 90, 10]
    },
    {
      name: 'Credit',
      data: [76, 85, 101, 98, 87, 105, 91, 114, 94, 56, 90, 10]
    }
    //   {
    //     name: 'Free Cash Flow',
    //     data: [35, 41, 36, 26, 45, 48, 52, 53, 41, 56, 90, 10]
    //   }
  ]

  const optionTsColumn = {
    chart: {
      toolbar: {
        show: false
      }
    },
    colors: ['#506fd9', '#85b6ff', '#a8b5c3'],
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',
        endingShape: 'rounded'
      }
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent']
    },
    xaxis: {
      categories: [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec'
      ]
    },
    fill: {
      opacity: 1
    },
    tooltip: {
      y: {
        formatter: function (val: any) {
          // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
          return '$ ' + val + ' thousands'
        }
      }
    }
  }

  return (
    <React.Fragment>
      <Header onSkin={setSkin} />
      <div className="main main-app p-3 p-lg-4">
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            {/* <ol className="breadcrumb fs-sm mb-1">
                            <li className="breadcrumb-item"><Link to="#">Dashboard</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Finance Monitoring</li>
                        </ol> */}
            <h4 className="main-title mb-0">Welcome to Dashboard</h4>
          </div>
        </div>
        <Row className="g-3">
          <Col>
            <Card className="card-one">
              <Card.Body className="p-3 p-xl-4">
                <Row className="justify-content-center g-3 mb-2 mb-xl-4">
                  <Col xs="6" sm="4" md>
                    <div className="finance-item">
                      <div className="finance-item-circle">
                        <h1>{shortenNumber(dashboardData.users)}</h1>
                        <label>Users</label>
                      </div>
                    </div>
                  </Col>
                  <Col xs="6" sm="4" md>
                    <div className="finance-item">
                      <div className="finance-item-circle">
                        <h1>{shortenNumber(dashboardData.assets)}</h1>
                        <label>Asset class</label>
                      </div>
                    </div>
                  </Col>
                  <Col xs="6" sm="4" md>
                    <div className="finance-item">
                      <div className="finance-item-circle bg-gray-400">
                        <h1>{shortenNumber(dashboardData.auctions)}</h1>
                        <label>Total Auction</label>
                      </div>
                    </div>
                  </Col>
                  <Col xs="6" sm="4" md>
                    <div className="finance-item">
                      <div className="finance-item-circle">
                        <h1>{shortenNumber(dashboardData.investments)}</h1>
                        <label>Total Investment</label>
                      </div>
                    </div>
                  </Col>
                  <Col xs="6" sm="4" md>
                    <div className="finance-item">
                      <div className="finance-item-circle">
                        <h1>{shortenNumber(dashboardData.withdrawRequests)}</h1>
                        <label>Withdrawal request</label>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Row className="g-3">
            <Col md="6" xl="4">
              <Card className="card-one">
                <Card.Header>
                  <Card.Title as="h6">Asset by class</Card.Title>
                  <Nav className="nav-icon nav-icon-sm ms-auto">
                    <Nav.Link href="">
                      <i className="ri-refresh-line"></i>
                    </Nav.Link>
                    <Nav.Link href="">
                      <i className="ri-more-2-fill"></i>
                    </Nav.Link>
                  </Nav>
                </Card.Header>
                <Card.Body className="p-3">
                  <div className="chart-donut-two mb-4">
                    <Doughnut data={dataPie} options={optionPie} />
                  </div>

                  <Row className="g-4 mb-2">
                    {[
                      {
                        name: 'Real estate',
                        value: '60',
                        percent: '40%',
                        progress: 60
                      },
                      {
                        name: 'Music',
                        value: '37',
                        percent: '25%',
                        progress: 75,
                        variant: 'ui-02'
                      },
                      {
                        name: 'Commodity',
                        value: '3,000',
                        percent: '20%',
                        progress: 80,
                        variant: 'gray-700'
                      },
                      {
                        name: 'Arts',
                        value: '2,250',
                        percent: '15%',
                        progress: 85,
                        variant: 'gray-500'
                      }
                    ].map((item, index) => (
                      <Col xs="6" key={index}>
                        <label className="fs-sm fw-medium mb-1">
                          {item.name}
                        </label>
                        <h3 className="card-value mb-2">
                          {item.value} <small>{item.percent}</small>
                        </h3>
                        <ProgressBar
                          now={item.progress}
                          className="ht-5 mb-0"
                          variant={item.variant}
                        />
                      </Col>
                    ))}
                  </Row>
                </Card.Body>
              </Card>
            </Col>

            <Col md="6" xl="4">
              {/* <h5 id="section2" className="main-subtitle">Column Chart</h5>
                            <p>Below is the column bar chart example.</p> */}

              <Card className="card-one">
                <Card.Header>
                  <Card.Title as="h6">Auctions</Card.Title>
                  <Nav className="nav-icon nav-icon-sm ms-auto">
                    <Nav.Link href="">
                      <i className="ri-refresh-line"></i>
                    </Nav.Link>
                    <Nav.Link href="">
                      <i className="ri-more-2-fill"></i>
                    </Nav.Link>
                  </Nav>
                </Card.Header>
                <Card.Body>
                  <ReactApexChart
                    series={seriesColumn}
                    options={optionColumn}
                    type="bar"
                    height={350}
                  />
                </Card.Body>
                {/* <Card.Footer>
                                    <pre><code className="language-html">{'<ReactApexChart series={seriesColumn} options={optionColumn} type="bar" height={350} />'}</code></pre>
                                </Card.Footer> */}
              </Card>
            </Col>

            <Col md="6" xl="4">
              {/* <h5 id="section2" className="main-subtitle">Column Chart</h5>
                            <p>Below is the column bar chart example.</p> */}

              <Card className="card-one">
                <Card.Header>
                  <Card.Title as="h6">Investments</Card.Title>
                  <Nav className="nav-icon nav-icon-sm ms-auto">
                    <Nav.Link href="">
                      <i className="ri-refresh-line"></i>
                    </Nav.Link>
                    <Nav.Link href="">
                      <i className="ri-more-2-fill"></i>
                    </Nav.Link>
                  </Nav>
                </Card.Header>
                <Card.Body>
                  <ReactApexChart
                    series={seriesColumn}
                    options={optionColumn}
                    type="bar"
                    height={350}
                  />
                </Card.Body>
                {/* <Card.Footer>
                                    <pre><code className="language-html">{'<ReactApexChart series={seriesColumn} options={optionColumn} type="bar" height={350} />'}</code></pre>
                                </Card.Footer> */}
              </Card>
            </Col>

            <Col md="6" xl="6">
              {/* <h5 id="section2" className="main-subtitle">Column Chart</h5>
                            <p>Below is the column bar chart example.</p> */}

              <Card className="card-one">
                <Card.Header>
                  <Card.Title as="h6">Transactions</Card.Title>
                  <Nav className="nav-icon nav-icon-sm ms-auto">
                    <Nav.Link href="">
                      <i className="ri-refresh-line"></i>
                    </Nav.Link>
                    <Nav.Link href="">
                      <i className="ri-more-2-fill"></i>
                    </Nav.Link>
                  </Nav>
                </Card.Header>
                <Card.Body>
                  <ReactApexChart
                    series={seriesTsColumn}
                    options={optionTsColumn}
                    type="bar"
                    height={350}
                  />
                </Card.Body>
                {/* <Card.Footer>
                                    <pre><code className="language-html">{'<ReactApexChart series={seriesColumn} options={optionColumn} type="bar" height={350} />'}</code></pre>
                                </Card.Footer> */}
              </Card>
            </Col>
          </Row>
        </Row>

        <Footer />
      </div>
    </React.Fragment>
  )
}

export default Dashboard
