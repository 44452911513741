import axios from 'axios'
import { getAuth } from '../utils/storage'

export const fracxnApi = axios.create({
  baseURL: process.env.REACT_APP_FRACXN_API_URL,
  timeout: (process.env.REACT_APP_API_TIMEOUT as number | undefined) ?? 100000,
})

const authInterceptor = (config: any) => {
  const auth = getAuth()
  if (auth) config.headers.Authorization = auth.accessToken
  return config
}

fracxnApi.interceptors.request.use(authInterceptor)
