import React, { createContext, Dispatch, useReducer } from 'react'
import {
  TRANSACTION_INITIAL_STATE,
  transactionReducer,
} from './transaction.reducer'
import { ActionType } from '../../interface/auth'

export const TransactionStateContext = createContext({
  ...TRANSACTION_INITIAL_STATE,
})
export const TransactionDispatchContext = createContext<Dispatch<ActionType>>(
  () => {}
)

interface Props {
  children: React.ReactNode
}

export const TransactionProvider: React.FC<Props> = ({ children }) => {
  const [state, dispatch] = useReducer(
    transactionReducer,
    TRANSACTION_INITIAL_STATE
  )

  return (
    <TransactionDispatchContext.Provider value={dispatch}>
      <TransactionStateContext.Provider value={state}>
        {children}
      </TransactionStateContext.Provider>
    </TransactionDispatchContext.Provider>
  )
}
