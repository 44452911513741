import React, { useContext, useEffect, useState } from 'react'
import PageTitle from '../../../components/PageTitle'
import Header from '../../../layouts/Header'
import { Button, Col, Form, Row } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { ASSET_DETAILS, USER_DETAILS } from '../../../routing/navigation'
import { insertPathParam } from '../../../utils/route'
import ModalComponent from '../../../components/Modal'
import {
  AssetDispatchContext,
  AssetStateContext
} from '../../../store/Assets/asset.provider'
import {
  assetLoadingAction,
  deleteAsset,
  listAssets
} from '../../../store/Assets/asset.actions'
import { toast } from 'react-toastify'
import Swal from 'sweetalert2'
import CreateAssetForm from './create'
import { AuctionStatusEnum } from '../../../interface/auction'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { createAuction } from '../../../services/internal/asset.service'
import { titleCase } from '../../../utils/string'
import { IProperty } from '../../../interface/asset'
import CustomDataTable from '../../../components/DataTable'
import { TableColumn } from 'react-data-table-component'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { faEye } from '@fortawesome/free-regular-svg-icons'

const emptyState = () => {
  return (
    <div className="d-flex justify-content-center mt-4">
      <h5 className="opacity-50">No Asset found.</h5>
    </div>
  )
}
const AssetManagement: React.FC = () => {
  const navigate = useNavigate()

  const currentSkin = localStorage.getItem('skin-mode') ? 'dark' : ''

  const { assets, loading } = useContext(AssetStateContext)

  const dispatch = useContext(AssetDispatchContext)

  const [skin, setSkin] = useState(currentSkin)
  const [assetId, setAssetId] = useState('')

  const [showCreateAssetModal, setShowCreateAssetModal] = useState(false)
  const toggleAssetCreateModal = (): void => {
    setShowCreateAssetModal((prev) => !prev)
  }

  const [showAddAuctionModal, setShowAddAuctionModal] = useState(false)
  const toggleAuctionAddModal = (assetId: string): void => {
    setAssetId(assetId)
    setShowAddAuctionModal((prev) => !prev)
  }

  const initialValues = {
    minimumBid: 5,
    maximumBid: 0,
    minimumPrice: 0,
    status: AuctionStatusEnum.ACTIVE,
    startTime: new Date(),
    endTime: new Date()
  }

  const auctionSchema = Yup.object().shape({
    minimumBid: Yup.number().required('Minimum Bid is required'),
    maximumBid: Yup.number().required('Maximum Bid is required'),
    minimumPrice: Yup.number().required('Minimum Price is required'),
    status: Yup.string().required('Status is required'),
    startTime: Yup.date().required('Start Time is required'),
    endTime: Yup.date()
      .required('End Time is required')
      .min(Yup.ref('startTime'), 'End Time must be later than Start Time')
  })

  const formik = useFormik({
    initialValues,
    validationSchema: auctionSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      try {
        console.log({ values })
        await createAuction(assetId, values)
        toggleAuctionAddModal(assetId)
        toast.success('Auction created successfully')
      } catch (error: any) {
        toast.error(
          error.message ? `${titleCase(error.message)}` : 'Something went wrong'
        )
      }
    }
  })

  const handleDeleteAsset = async (id: string): Promise<void> => {
    try {
      Swal.fire({
        title: 'Are you sure you want to delete this Asset?',
        showCancelButton: true,
        confirmButtonText: 'Delete'
      }).then(async (result) => {
        if (result.isConfirmed) {
          dispatch(await deleteAsset(id))
          toast.success('Asset deleted')
          Swal.fire('Deleted!', '', 'success')
        }
      })
    } catch (error) {
      toast.error('Something went wrong')
    }
  }

  const onDeleteAssetClick = (id: string): void => {
    handleDeleteAsset(id)
  }

  const generateActionsColumn = (row: IProperty) => (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <Button
        variant="link"
        title="View Asset"
        className="btn btn-link p-0"
        onClick={() => view(row._id)}
      >
        <FontAwesomeIcon size="lg" icon={faEye} className="text-success" />
      </Button>

      <Button
        title="Add Auction"
        variant="link"
        className="btn btn-link p-0"
        onClick={() => toggleAuctionAddModal(row._id)}
      >
        <FontAwesomeIcon icon={faPlus} className="mx-2 fa-lg text-primary" />
      </Button>

      <Button
        variant="link"
        title="Delete Asset"
        className="btn btn-link p-0"
        onClick={() => onDeleteAssetClick(row._id)}
      >
        <FontAwesomeIcon size="lg" icon={faTrashAlt} className="text-danger" />
      </Button>
    </div>
  )

  const view = (id: string): void => {
    const url = insertPathParam(`/${ASSET_DETAILS}`, { id })
    navigate(url)
  }
  const columns: TableColumn<IProperty>[] = [
    {
      name: 'Title',
      cell: (row) => row.title
    },
    {
      name: 'Type',
      cell: (row) => row.type
    },
    {
      name: 'Initial Buy Price',
      cell: (row) => row.initialBuyPrice
    },
    {
      name: 'Amount',
      cell: (row) => row.price
    },
    {
      name: 'Status',
      cell: (row) => row.status
    },
    {
      name: 'Actions',
      cell: generateActionsColumn
    }
  ]

  const switchSkin = (skin: any): void => {
    if (skin === 'dark') {
      const btnWhite = document.getElementsByClassName('btn-white')
      // @ts-expect-error
      for (const btn of btnWhite) {
        btn.classList.add('btn-outline-primary')
        btn.classList.remove('btn-white')
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName(
        'btn-outline-primary'
      )
      // @ts-expect-error
      for (const btn of btnOutlinePrimary) {
        btn.classList.remove('btn-outline-primary')
        btn.classList.add('btn-white')
      }
    }
  }

  const getAssets = async () => {
    dispatch(assetLoadingAction(true))
    dispatch(await listAssets())
  }

  switchSkin(skin)
  useEffect(() => {
    switchSkin(skin)
  }, [skin])

  useEffect(() => {
    getAssets()
  }, [])

  useEffect(() => {
    if (formik.isSubmitting) {
      Object.entries(formik.errors).map((field, error) => {
        toast.error(field[1] as string)
        return {}
      })
    }
  }, [formik.isSubmitting])

  return (
    <div className="main main-app p-3 p-lg-4">
      <Header onSkin={setSkin} />
      <div className="d-sm-flex align-items-center justify-content-between mb-4">
        <PageTitle title="Assets" />

        <div className="d-flex align-items-center gap-2 mt-3 mt-md-0">
          <Button
            variant="primary"
            className="d-flex align-items-center gap-2"
            onClick={toggleAssetCreateModal}
          >
            <i className="ri-add-line fs-18 lh-1"></i>Create Asset
          </Button>
        </div>
      </div>

      <div className="mb-5">
        <CustomDataTable
          columns={columns}
          data={assets}
          noDataComponent={emptyState()}
          progressPending={loading}
          persistTableHead
        />
      </div>
      <ModalComponent
        handleClose={() => toggleAuctionAddModal(assetId)}
        show={showAddAuctionModal}
        title="Add Auction"
      >
        <div className="create_asset_modal-content">
          <form className="mb-4" onSubmit={formik.handleSubmit}>
            <div className="mb-3">
              <Row>
                <Col lg={6} className="mb-3">
                  <Form.Label htmlFor="minimumBid">Minimum Bid</Form.Label>
                  <Form.Control
                    placeholder="Enter Minimum Bid"
                    id="minimumBid"
                    type="number"
                    {...formik.getFieldProps('minimumBid')}
                  />
                </Col>

                <Col lg={6} className="mb-3">
                  <Form.Label htmlFor="maximumBid">Maximum Bid</Form.Label>
                  <Form.Control
                    placeholder="Enter Maximum Bid"
                    id="maximumBid"
                    type="number"
                    {...formik.getFieldProps('maximumBid')}
                  />
                </Col>

                <Col lg={6} className="mb-3">
                  <Form.Label htmlFor="minimumPrice">Minimum Price</Form.Label>
                  <Form.Control
                    placeholder="Enter Minimum Price"
                    id="minimumPrice"
                    type="number"
                    {...formik.getFieldProps('minimumPrice')}
                  />
                </Col>

                <Col lg={6} className="mb-3">
                  <Form.Label htmlFor="status">Status</Form.Label>
                  <Form.Control
                    as="select"
                    id="status"
                    {...formik.getFieldProps('status')}
                  >
                    <option value="">Select status</option>
                    {Object.entries(AuctionStatusEnum).map(([key, value]) => (
                      <option key={key} value={value}>
                        {key}
                      </option>
                    ))}
                  </Form.Control>
                </Col>

                <Col lg={6} className="mb-3">
                  <Form.Label htmlFor="startTime">Start Time</Form.Label>
                  <Form.Control
                    placeholder="Enter Start Time"
                    id="startTime"
                    type="datetime-local"
                    {...formik.getFieldProps('startTime')}
                  />
                </Col>

                <Col lg={6} className="mb-3">
                  <Form.Label htmlFor="endTime">End Time</Form.Label>
                  <Form.Control
                    placeholder="Enter End Time"
                    id="endTime"
                    type="datetime-local"
                    {...formik.getFieldProps('endTime')}
                  />
                </Col>
              </Row>
            </div>
            <div className="d-flex justify-content-end">
              <Button type="submit" variant="primary">
                Create auction
              </Button>
            </div>
          </form>
        </div>
      </ModalComponent>

      {/* Create Asset Modal */}
      <ModalComponent
        handleClose={toggleAssetCreateModal}
        show={showCreateAssetModal}
        title="Create Asset"
        fullscreen={true}
      >
        <CreateAssetForm onClose={toggleAssetCreateModal} />
      </ModalComponent>
    </div>
  )
}

export default AssetManagement
