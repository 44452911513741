export const insertPathParam = (
  url: string,
  paths: { [key: string]: string | number }
): string => {
  const pathNames = Object.keys(paths)
  let parsedUrl = url
  for (const pathName of pathNames) {
    const value = paths[pathName].toString()
    const pattern = new RegExp(`:\\b${pathName}\\b`, 'g')
    parsedUrl = parsedUrl.replace(pattern, value)
  }
  return parsedUrl
}
