export const REGISTER = 'auth/register'
export const LOGIN = 'admin/login'
export const LOGOUT = 'auth/logout'
export const FETCH_AUTH_USER = 'admin/auth'
export const UPDATE_USER = 'user'
export const REQUEST_RESET = 'auth/request-reset'
export const VERIFY_TOKEN = 'auth/reset'
export const RESET_PASSWORD = 'auth/reset'
export const UPDATE_PASSWORD = 'auth/password'

export const ASSETS = 'admin/properties'

export const TRANSACTIONS = 'admin/transactions'
export const TRANSACTION_HISTORY = 'admin/transactions/history'
export const TRANSACTION = 'admin/transaction'

export const DASHBOARD = 'admin/dashboard'

export const USERS = 'admin/users'
export const ADMINS = 'admin'

export const AUCTIONS = 'admin/auctions'

export const AUTH_ENDPOINTS = {
  REGISTER,
  LOGIN,
  LOGOUT,
  REQUEST_RESET,
  VERIFY_TOKEN,
  RESET_PASSWORD,
  UPDATE_PASSWORD,
  UPDATE_USER,
  FETCH_AUTH_USER
}

export const ASSET_ENDPOINTS = {
  ASSETS
}

export const AUCTION_ENDPOINTS = {
  AUCTIONS
}

export const USER_ENDPOINTS = {
  USERS
}

export const ADMIN_ENDPOINTS = {
  ADMINS
}

export const TRANSACTION_ENDPOINTS = {
  TRANSACTIONS,
  TRANSACTION,
  TRANSACTION_HISTORY,
  DASHBOARD
}
