import { ITransaction } from '../../interface/transaction'
import { ActionType } from '../../interface/auth'
import { IPageMeta } from '../../interface/global'
import { transactionActions } from './transaction.actions'

export interface TransactionType {
  transactions: ITransaction[]
  transaction?: ITransaction
  loading: boolean
  meta?: IPageMeta
}

export const TRANSACTION_INITIAL_STATE: TransactionType = {
  transactions: [],
  transaction: undefined,
  loading: true,
  meta: undefined
}

export const transactionReducer = (
  state = TRANSACTION_INITIAL_STATE,
  action: ActionType
) => {
  switch (action.type) {
    case transactionActions.LIST_TRANSACTIONS:
      return {
        ...state,
        transactions: action.payload.transactions,
        meta: {
          current_page: action.payload.currentPage,
          total: action.payload.totalCount
        },
        loading: false
      }
    case transactionActions.FIND_TRANSACTION:
      return {
        ...state,
        transaction: action.payload,
        loading: false
      }
    case transactionActions.LOAD_TRANSACTION:
      return {
        ...state,
        loading: action.payload
      }

    default:
      throw new Error(`Unknown action type: ${action.type}`)
  }
}
