import { fracxnApi } from '../http'
import { ASSET_ENDPOINTS } from '../../constants/endpoints'
import { handleError } from '../../utils/error'
import { ImageType, PropertyCreationParams } from '../../interface/asset'
import { AuctionRequest } from '../../interface/auction'

export const create = async (payload: PropertyCreationParams) => {
  try {
    const { data } = await fracxnApi.post(ASSET_ENDPOINTS.ASSETS, payload)
    return data
  } catch (error: any) {
    throw new Error(handleError(error))
  }
}

export const list = async () => {
  try {
    const { data } = await fracxnApi.get(ASSET_ENDPOINTS.ASSETS)
    return data
  } catch (error: any) {
    throw new Error(handleError(error))
  }
}

export const find = async (id: string) => {
  try {
    const { data } = await fracxnApi.get(`${ASSET_ENDPOINTS.ASSETS}/${id}`)
    return data
  } catch (error: any) {
    throw new Error(handleError(error))
  }
}

export const update = async (id: string, payload: PropertyCreationParams) => {
  try {
    const { data } = await fracxnApi.put(
      `${ASSET_ENDPOINTS.ASSETS}/${id}`,
      payload
    )
    return data
  } catch (error: any) {
    throw new Error(handleError(error))
  }
}

export const deleteAsset = async (id: string) => {
  try {
    const { data } = await fracxnApi.delete(`${ASSET_ENDPOINTS.ASSETS}/${id}`)
    return data
  } catch (error: any) {
    throw new Error(handleError(error))
  }
}

export const createAuction = async (
  assetId: string,
  payload: AuctionRequest
) => {
  try {
    const { data } = await fracxnApi.post(
      `${ASSET_ENDPOINTS.ASSETS}/${assetId}/auction`,
      payload
    )
    return data
  } catch (error: any) {
    throw new Error(handleError(error))
  }
}

export const uploadAssetImage = async (file: any) => {
  const formData = new FormData()
  formData.append('file', file)
  try {
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }

    const { data } = await fracxnApi.post(
      `${ASSET_ENDPOINTS.ASSETS}/upload-images`,
      formData,
      config
    )
    return data
  } catch (error: any) {
    throw new Error(handleError(error))
  }
}

export const removeAssetImages = async (payload: {
  arrayOfAllImages: ImageType[]
  uploadedImages: ImageType[]
}) => {
  try {
    const { data } = await fracxnApi.post(
      `${ASSET_ENDPOINTS.ASSETS}/remove-images`,
      payload
    )
    return data
  } catch (error: any) {
    throw new Error(handleError(error))
  }
}
