import { IAdmin } from '../../interface/admin'
import { ActionType } from '../../interface/auth'
import { IPageMeta } from '../../interface/global'
import { adminActions } from './admin.actions'

export interface AdminType {
  admins: IAdmin[]
  admin?: IAdmin
  loading: boolean
  meta?: IPageMeta
}

export const ADMIN_INITIAL_STATE: AdminType = {
  admins: [],
  admin: undefined,
  loading: false,
  meta: undefined
}

export const adminReducer = (
  state = ADMIN_INITIAL_STATE,
  action: ActionType
) => {
  switch (action.type) {
    case adminActions.LIST_ADMINS:
      return {
        ...state,
        admins: action.payload,
        loading: false,
        newAdmin: false
      }
    case adminActions.BLOCK_ADMIN:
      return {
        ...state,
        loading: false
      }
    case adminActions.ADMIN_ACTION:
      return {
        ...state,
        loading: false
      }
    case adminActions.ADMIN_LOADING:
      return {
        ...state,
        loading: true
      }
    default:
      throw new Error(`Unknown action type: ${action.type}`)
  }
}
