import { fracxnApi } from '../http'
import { handleError } from '../../utils/error'
import { TRANSACTION_ENDPOINTS } from '../../constants/endpoints'
import {
  TransactionEnum,
  TransactionTypeEnum
} from '../../interface/transaction'
import { buildPageQuery } from '../../utils/string'

export const list = async (
  pageQuery?: Record<string, string | boolean | number>
) => {
  try {
    const url = `${TRANSACTION_ENDPOINTS.TRANSACTIONS}${buildPageQuery(
      pageQuery
    )}`
    const { data } = await fracxnApi.get(url)
    return data
  } catch (error: any) {
    throw new Error(handleError(error))
  }
}

export const listHistory = async (
  pageQuery?: Record<string, string | boolean | number>
) => {
  try {
    const url = `${TRANSACTION_ENDPOINTS.TRANSACTION_HISTORY}${buildPageQuery(
      pageQuery
    )}`
    const { data } = await fracxnApi.get(url)
    return data
  } catch (error: any) {
    throw new Error(handleError(error))
  }
}

export const find = async (id: string) => {
  try {
    const { data } = await fracxnApi.get(
      `${TRANSACTION_ENDPOINTS.TRANSACTIONS}/${id}`
    )
    return data
  } catch (error: any) {
    throw new Error(handleError(error))
  }
}

export const approveTransaction = async (
  reference: string,
  payload: { userId: string; paymentType: TransactionTypeEnum }
) => {
  try {
    const { data } = await fracxnApi.post(
      `${TRANSACTION_ENDPOINTS.TRANSACTION}/${reference}/confirm`,
      { ...payload, paymentType: payload.paymentType.toLowerCase() }
    )
    return data
  } catch (error: any) {
    throw new Error(handleError(error))
  }
}

export const cancelTransaction = async (
  reference: string,
  payload: { userId: string; paymentType: TransactionTypeEnum }
) => {
  try {
    const { data } = await fracxnApi.post(
      `${TRANSACTION_ENDPOINTS.TRANSACTION}/${reference}/cancel`,
      { ...payload, paymentType: payload.paymentType.toLowerCase() }
    )
    return data
  } catch (error: any) {
    throw new Error(handleError(error))
  }
}

export const getDashboardStats = async () => {
  try {
    const { data } = await fracxnApi.get(`${TRANSACTION_ENDPOINTS.DASHBOARD}`)
    return data
  } catch (error: any) {
    throw new Error(handleError(error))
  }
}
