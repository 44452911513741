import * as TransactionService from '../../services/internal/transaction.service'
import { ActionType } from '../../interface/auth'
import { handleError } from '../../utils/error'

export const transactionActions = {
  LIST_TRANSACTIONS: 'LIST_TRANSACTIONS',
  FIND_TRANSACTION: 'FIND_TRANSACTION',
  LOAD_TRANSACTION: 'LOAD_TRANSACTION'
}

export const listTransactions = async (
  pageQuery?: Record<string, string | boolean | number>
): Promise<ActionType> => {
  try {
    const response = await TransactionService.list(pageQuery)
    return {
      type: transactionActions.LIST_TRANSACTIONS,
      payload: response.data
    }
  } catch (error: any) {
    throw new Error(handleError(error))
  }
}

export const listTransactionHistory = async (
  pageQuery?: Record<string, string | boolean | number>
): Promise<ActionType> => {
  try {
    const response = await TransactionService.listHistory(pageQuery)
    return {
      type: transactionActions.LIST_TRANSACTIONS,
      payload: response.data
    }
  } catch (error: any) {
    throw new Error(handleError(error))
  }
}

export const findTransaction = async (id: string): Promise<ActionType> => {
  try {
    const response = await TransactionService.find(id)
    return {
      type: transactionActions.FIND_TRANSACTION,
      payload: response.data
    }
  } catch (error: any) {
    throw new Error(handleError(error))
  }
}

export const transactionLoadingAction = (payload: boolean) => {
  return {
    type: transactionActions.LOAD_TRANSACTION,
    payload
  }
}
