import React, { useContext, useEffect } from 'react'
import { Form, Button, Col, Row } from 'react-bootstrap'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import {
  AdminDispatchContext,
  AdminStateContext
} from '../../store/Admin/admin.provider'
import { inviteAdmin } from '../../store/Admin/admin.actions'
import { toast } from 'react-toastify'
import { titleCase } from '../../utils/string'

interface InviteAdminFormProps {
  onClose: () => void
}

const initialValues = {
  email: '',
  name: ''
}

const InviteAdminForm: React.FC<InviteAdminFormProps> = ({ onClose }) => {
  const dispatch = useContext(AdminDispatchContext)

  const inviteSchema = Yup.object().shape({
    email: Yup.string()
      .email('Wrong email format')
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required('Email is required'),
    name: Yup.string().required('Name is required')
  })

  const formik = useFormik({
    initialValues,
    validationSchema: inviteSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      try {
        dispatch(await inviteAdmin(values))
        onClose()
        toast.success('Invite sent successfully')
      } catch (error: any) {
        toast.error(
          error.message ? `${titleCase(error.message)}` : 'Something went wrong'
        )
      }
    }
  })

  useEffect(() => {
    if (formik.isSubmitting) {
      Object.entries(formik.errors).map((field, error) => {
        console.log({ field, error })
        toast.error(field[1])
        return {}
      })
    }
  }, [formik.isSubmitting])

  return (
    <Form onSubmit={formik.handleSubmit}>
      <Row>
        <Col lg={12} className="mb-3">
          <Form.Label htmlFor="email">Email </Form.Label>
          <Form.Control
            id="email"
            placeholder="Enter email"
            {...formik.getFieldProps('email')}
          />
        </Col>
        <Col lg={12} className="mb-3">
          <Form.Label htmlFor="name">Name</Form.Label>
          <Form.Control
            type="text"
            id="name"
            placeholder="Enter name"
            {...formik.getFieldProps('name')}
          />
        </Col>
      </Row>

      <div className="d-flex justify-content-end">
        <Button variant="secondary" className="me-2" onClick={onClose}>
          Cancel
        </Button>
        <Button type="submit" variant="primary">
          Invite
        </Button>
      </div>
    </Form>
  )
}

export default InviteAdminForm
