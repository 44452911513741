import React, { FC, useContext, useEffect } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import Sidebar from './Sidebar'
import { getAuthUserAction } from '../store/Auth/auth.actions'
import {
  AuthDispatchContext,
  AuthStateContext,
} from '../store/Auth/auth.provider'

const Main: FC = () => {
  const offsets = ['/apps/file-manager', '/apps/email', '/apps/calendar']
  const { pathname } = useLocation()
  const bc = document.body.classList
  const dispatch = useContext(AuthDispatchContext)
  const { user } = useContext(AuthStateContext)

  const getAuth = async () => {
    dispatch(await getAuthUserAction())
  }

  useEffect(() => {
    getAuth()
  }, [])
  // set sidebar to offset
  offsets.includes(pathname)
    ? bc.add('sidebar-offset')
    : bc.remove('sidebar-offset')

  // auto close sidebar when switching pages in mobile
  bc.remove('sidebar-show')

  // scroll to top when switching pages
  window.scrollTo(0, 0)

  return (
    <React.Fragment>
      <Sidebar />
      <Outlet />
    </React.Fragment>
  )
}
export default Main
