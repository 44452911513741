import React, { FC } from 'react'

const PageTitle: FC<{ title: string }> = ({ title }) => {
  return (
    <div className="Title-header">
      <h4 className="main-title">{title}</h4>
    </div>
  )
}
export default PageTitle
