import { fracxnApi } from '../http'
import { handleError } from '../../utils/error'
import { ADMIN_ENDPOINTS } from '../../constants/endpoints'

export const list = async () => {
  try {
    const { data } = await fracxnApi.get(ADMIN_ENDPOINTS.ADMINS)
    return data
  } catch (error: any) {
    throw new Error(handleError(error))
  }
}

export const block = async (id: string) => {
  try {
    const { data } = await fracxnApi.post(
      `${ADMIN_ENDPOINTS.ADMINS}/${id}/block`
    )
    return data
  } catch (error: any) {
    throw new Error(handleError(error))
  }
}

export const unblock = async (id: string) => {
  try {
    const { data } = await fracxnApi.post(
      `${ADMIN_ENDPOINTS.ADMINS}/${id}/unblock`
    )
    return data
  } catch (error: any) {
    throw new Error(handleError(error))
  }
}

export const invite = async (payload: { email: string; name: string }) => {
  try {
    const { data } = await fracxnApi.post(
      `${ADMIN_ENDPOINTS.ADMINS}/Invite`,
      payload
    )
    return data
  } catch (error: any) {
    throw new Error(handleError(error))
  }
}

export const acceptInvite = async (
  token: string,
  payload: { email: string }
) => {
  try {
    const { data } = await fracxnApi.post(
      `${ADMIN_ENDPOINTS.ADMINS}/invite/${token}/accept`,
      payload
    )
    return data
  } catch (error: any) {
    throw new Error(handleError(error))
  }
}
