export const SIGNUP = 'signup'
export const GET_STARTED = 'getstarted'
export const ROOT = '/*'
export const AUTH_ROOT = 'auth/*'
export const FORGOT_PASSWORD = 'forgot-password'
export const NEW_PASSWORD = 'new-password'
export const DASHBOARD = 'dashboard'
export const USERS = 'users'
export const USER_DETAILS = 'users/:id'
export const ADMINS = 'admins'
export const ASSETS = 'assets'
export const ASSET_DETAILS = 'assets/:id'
export const AUCTIONS = 'auctions'
export const SIGNIN = 'login'
export const TRANSACTIONS = 'transactions'
export const TRANSACTION_HISTORY = 'transactions/history'
