import React, { createContext, Dispatch, useReducer } from 'react'
import { ADMIN_INITIAL_STATE, adminReducer } from './admin.reducer'
import { ActionType } from '../../interface/auth'

export const AdminStateContext = createContext({ ...ADMIN_INITIAL_STATE })
export const AdminDispatchContext = createContext<Dispatch<ActionType>>(
  () => {}
)

interface Props {
  children: React.ReactNode
}

export const AdminProvider: React.FC<Props> = ({ children }) => {
  const [state, dispatch] = useReducer(adminReducer, ADMIN_INITIAL_STATE)

  return (
    <AdminDispatchContext.Provider value={dispatch}>
      <AdminStateContext.Provider value={state}>
        {children}
      </AdminStateContext.Provider>
    </AdminDispatchContext.Provider>
  )
}
