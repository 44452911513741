import { fracxnApi } from '../http'
import { AUTH_ENDPOINTS } from '../../constants/endpoints'
import { ILogin, IRequestPassword, IResetPassword } from '../../interface/auth'
import { AxiosRequestHeaders } from 'axios'
import { handleError } from '../../utils/error'

export const login = async (credentials: ILogin) => {
  try {
    const { data } = await fracxnApi.post(AUTH_ENDPOINTS.LOGIN, credentials)
    return data
  } catch (error: any) {
    throw new Error(handleError(error))
  }
}

export const requestReset = async (email: IRequestPassword) => {
  try {
    const { data } = await fracxnApi.post(AUTH_ENDPOINTS.REQUEST_RESET, email)
    return data
  } catch (error: any) {
    throw new Error(handleError(error))
  }
}

export const verifyToken = async (token: string) => {
  try {
    const { data } = await fracxnApi.get(
      `${AUTH_ENDPOINTS.VERIFY_TOKEN}/${token}`
    )
    return data
  } catch (error: any) {
    throw new Error(handleError(error))
  }
}

export const resetPassword = async (passwordPayload: IResetPassword) => {
  try {
    const { data } = await fracxnApi.put(
      AUTH_ENDPOINTS.UPDATE_PASSWORD,
      passwordPayload
    )
    return data
  } catch (error: any) {
    throw new Error(handleError(error))
  }
}

export const getAuthUser = async () => {
  return await fracxnApi.get<AxiosRequestHeaders>(
    AUTH_ENDPOINTS.FETCH_AUTH_USER
  )
}

export const logout = async () => {
  try {
    const { data } = await fracxnApi.post(AUTH_ENDPOINTS.LOGOUT)
    return data
  } catch (error: any) {
    throw new Error(handleError(error))
  }
}
