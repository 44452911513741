import React, { createContext, Dispatch, useReducer } from 'react'
import { ASSET_INITIAL_STATE, assetReducer } from './asset.reducer'
import { ActionType } from '../../interface/auth'

export const AssetStateContext = createContext({ ...ASSET_INITIAL_STATE })
export const AssetDispatchContext = createContext<Dispatch<ActionType>>(
  () => {}
)

interface Props {
  children: React.ReactNode
}

export const AssetProvider: React.FC<Props> = ({ children }) => {
  const [state, dispatch] = useReducer(assetReducer, ASSET_INITIAL_STATE)

  return (
    <AssetDispatchContext.Provider value={dispatch}>
      <AssetStateContext.Provider value={state}>
        {children}
      </AssetStateContext.Provider>
    </AssetDispatchContext.Provider>
  )
}
