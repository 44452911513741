import React, { FC, ReactNode } from 'react'
import { Modal } from 'react-bootstrap'

interface IModalProps {
  show: boolean
  handleClose: () => void
  children: ReactNode
  title: string
  fullscreen?: string | true
  size?: 'xl' | 'sm' | 'lg'
}

const ModalComponent: FC<IModalProps> = ({
  show,
  handleClose,
  children,
  title,
  fullscreen,
  size
}) => {
  return (
    <Modal
      show={show}
      size={size ?? 'xl'}
      fullscreen={fullscreen}
      backdrop="static"
      keyboard={false}
      onHide={handleClose}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
    </Modal>
  )
}

export default ModalComponent
