import React, { createContext, Dispatch, useReducer } from 'react'
import { USER_INITIAL_STATE, userReducer } from './user.reducer'
import { ActionType } from '../../interface/auth'

export const UserStateContext = createContext({ ...USER_INITIAL_STATE })
export const UserDispatchContext = createContext<Dispatch<ActionType>>(() => {})

interface Props {
  children: React.ReactNode
}

export const UserProvider: React.FC<Props> = ({ children }) => {
  const [state, dispatch] = useReducer(userReducer, USER_INITIAL_STATE)

  return (
    <UserDispatchContext.Provider value={dispatch}>
      <UserStateContext.Provider value={state}>
        {children}
      </UserStateContext.Provider>
    </UserDispatchContext.Provider>
  )
}
