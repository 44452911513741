import { IUser } from '../../interface/user'
import { ActionType } from '../../interface/auth'
import { IPageMeta } from '../../interface/global'
import { userActions } from './user.actions'
import { IBid, IInvestmentResponse } from '../../interface/bids'
import { IBidResponse } from '../../interface/auction'
import { ITransaction } from '../../interface/transaction'

export interface UserType {
  users: IUser[]
  user?: IUser
  loading: boolean
  meta?: IPageMeta
  investments: Array<IInvestmentResponse>
  investment: undefined
  bids: Array<IBid>
  bid: undefined
  withdrawals: Array<ITransaction>
  deposits: Array<ITransaction>
}

export const USER_INITIAL_STATE: UserType = {
  users: [],
  user: undefined,
  loading: true,
  meta: undefined,
  investments: [],
  investment: undefined,
  bids: [],
  bid: undefined,
  withdrawals: [],
  deposits: []
}

export const userReducer = (state = USER_INITIAL_STATE, action: ActionType) => {
  switch (action.type) {
    case userActions.LIST_USERS:
      return {
        ...state,
        users: action.payload,
        loading: false,
        newUser: false
      }
    case userActions.FIND_USER:
      return {
        ...state,
        user: action.payload,
        loading: false
      }
    case userActions.BLOCK_USER:
      return {
        ...state,
        loading: false
      }
    case userActions.UNBLOCK_USER:
      return {
        ...state,
        loading: false
      }
    case userActions.USER_LOADING:
      return {
        ...state,
        loading: true
      }
    case userActions.LIST_INVESTMENTS:
      return {
        ...state,
        investments: action.payload,
        loading: false
      }
    case userActions.FIND_INVESTMENT:
      return {
        ...state,
        investment: action.payload
      }
    case userActions.LIST_BIDS:
      return {
        ...state,
        bids: action.payload,
        loading: false
      }
    case userActions.FIND_BID:
      return {
        ...state,
        bid: action.payload,
        loading: false
      }
    case userActions.LIST_USERS_DEPOSITS:
      return {
        ...state,
        deposits: action.payload
      }
    case userActions.LIST_USER_WITHDRAWALS:
      return {
        ...state,
        withdrawals: action.payload
      }
    case userActions.LOADING:
      return {
        ...state,
        loading: action.payload
      }

    default:
      throw new Error(`Unknown action type: ${action.type}`)
  }
}
