import React, { useContext, useEffect, useState } from 'react'
import PageTitle from '../../components/PageTitle'
import Header from '../../layouts/Header'
import { Col, Nav, Row } from 'react-bootstrap'
import {
  TransactionDispatchContext,
  TransactionStateContext
} from '../../store/Transaction/transaction.provider'
import {
  listTransactionHistory,
  transactionLoadingAction
} from '../../store/Transaction/transaction.actions'
import {
  ITransaction,
  TransactionTypeEnum,
  TxnStatusEnum
} from '../../interface/transaction'
import { useLocation } from 'react-router'
import CustomDataTable from '../../components/DataTable'
import { TableColumn } from 'react-data-table-component'
import './transactionStyle.css'

const columns: TableColumn<ITransaction>[] = [
  {
    name: 'Reference',
    cell: (row) => `${row.reference}`,
    grow: 2
  },
  {
    name: 'User',
    cell: (row) => (
      <div title={row.user?.name ?? row.user?.email} className="user-cell">
        {row.user?.name ?? row.user?.email}
      </div>
    )
  },
  {
    name: 'Amount',
    cell: (row) => `${row.currency} ${row.amount}`
  },
  {
    name: 'Type',
    cell: (row) => row.type
  },
  {
    name: 'Status',
    cell: (row) => (
      <p
        className={`d-block fs-xs text-${
          row.status === TxnStatusEnum.SUCCESS
            ? 'success'
            : row.status === TxnStatusEnum.PENDING
            ? 'warning'
            : 'danger'
        }`}
      >
        {row.status}
      </p>
    )
  },
  {
    name: 'Source',
    cell: (row) => row.source
  },
  {
    name: 'Category',
    cell: (row) => row.category
  }
]

const emptyState = () => {
  return (
    <div className="d-flex justify-content-center mt-4">
      <h5 className="opacity-50">No transactions available.</h5>
    </div>
  )
}
const TransactionHistory: React.FC = () => {
  const currentSkin = localStorage.getItem('skin-mode') ? 'dark' : ''

  const { transactions, loading, meta } = useContext(TransactionStateContext)

  const dispatch = useContext(TransactionDispatchContext)

  const [skin, setSkin] = useState(currentSkin)
  const [activeTab, setActiveTab] = useState('all')

  const handleTabChange = (tab: string) => {
    setActiveTab(tab)
  }

  const switchSkin = (skin: any): void => {
    if (skin === 'dark') {
      const btnWhite = document.getElementsByClassName('btn-white')
      // @ts-expect-error
      for (const btn of btnWhite) {
        btn.classList.add('btn-outline-primary')
        btn.classList.remove('btn-white')
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName(
        'btn-outline-primary'
      )
      // @ts-expect-error
      for (const btn of btnOutlinePrimary) {
        btn.classList.remove('btn-outline-primary')
        btn.classList.add('btn-white')
      }
    }
  }

  const getTransactions = async (page: number = 1, limit: number = 10) => {
    if (activeTab === 'deposits') {
      dispatch(
        await listTransactionHistory({
          type: TransactionTypeEnum.CREDIT,
          page,
          limit
        })
      )
      return
    }
    if (activeTab === 'withdrawals') {
      dispatch(
        await listTransactionHistory({
          type: TransactionTypeEnum.DEBIT,
          page,
          limit
        })
      )
      return
    }
    dispatch(await listTransactionHistory({ page, limit }))
  }

  const handlePageChange = (page: number) => {
    getTransactions(page)
  }

  const handlePerRowsChange = (newPerPage: number, page: number): void => {
    getTransactions(page, newPerPage)
  }

  switchSkin(skin)
  useEffect(() => {
    switchSkin(skin)
  }, [skin])

  useEffect(() => {
    dispatch(transactionLoadingAction(true))
    getTransactions()
  }, [activeTab])

  return (
    <div className="main main-app p-3 p-lg-4">
      <Header onSkin={setSkin} />
      <PageTitle title="Transaction History" />
      <div className="mb-2">
        <Row>
          <Col xl>
            <Nav className="nav-line mb-4">
              <Nav.Link
                href="#all"
                className={activeTab === 'all' ? 'active' : ''}
                onClick={() => handleTabChange('all')}
              >
                All
              </Nav.Link>
              <Nav.Link
                href="#deposits"
                className={activeTab === 'deposits' ? 'active' : ''}
                onClick={() => handleTabChange('deposits')}
              >
                Deposits
              </Nav.Link>
              <Nav.Link
                href="#withdrawals"
                className={activeTab === 'withdrawals' ? 'active' : ''}
                onClick={() => handleTabChange('withdrawals')}
              >
                Withdrawals
              </Nav.Link>
            </Nav>

            <CustomDataTable
              columns={columns}
              data={transactions}
              noDataComponent={emptyState()}
              progressPending={loading}
              persistTableHead
              paginationServer
              paginationTotalRows={meta?.total ?? 0}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
            />
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default TransactionHistory
