import { AssetModelEnum, IAsset, IProperty } from './asset'
import { INetworkResponse } from './network'

export interface IAuction {
  _id: string
  minimumBid: number
  maximumBid: number
  minimumPrice: number
  status: AuctionStatusEnum
  startTime: Date
  endTime: Date
  assetType: AssetModelEnum
  asset: IProperty
  totalBid?: number
  address?: string
  percentageFunded?: number
  investorsCount?: number
  createdAt: Date
}

export enum AuctionStatusEnum {
  ACTIVE = 'ACTIVE',
  CLOSED = 'CLOSED',
  CONCLUDED = 'CONCLUDED',
  CANCELLED = 'CANCELLED'
}

export type CreateAuctionRequest = Pick<
  IAuction,
  | 'assetType'
  | 'asset'
  | 'minimumBid'
  | 'maximumBid'
  | 'minimumPrice'
  | 'status'
  | 'startTime'
  | 'endTime'
  | 'totalBid'
  | 'address'
>

export type AuctionRequest = Pick<
  IAuction,
  | 'minimumBid'
  | 'maximumBid'
  | 'minimumPrice'
  | 'status'
  | 'startTime'
  | 'endTime'
>

export interface UpdateAuctionRequest {
  minimumBid?: string
  maximumBid?: string
  status?: AuctionStatusEnum
  startTime?: Date
  endTime?: Date
  totalBid?: number
  address?: string
}

export interface IAuctionResponseData {
  _id: string
  totalBid: number
  investorsCount: number
  favorite: boolean
  percentageFunded: number
  relatedAuctions: any[]
  property: {
    _id: string
    type: string
    title: string
    area: string
    distributionFrequency: string
    price: string
    rentPerMonth: string
    amenity: Array<{
      name: string
      icon: string
      _id: string
    }>
    description: string
    locationInfo: {
      latitude: number
      longitude: number
      country: string
      state: string
      street: string
      city: string
      zip: string
      _id: string
    }
    status: string
    specification: Array<{
      name: string
      value: string
      _id: string
    }>
    image: Array<{
      thumbnail: {
        url: string
        name: string
      }
      _id: string
    }>
    custom: Array<{
      name: string
      value: string
      _id: string
    }>
    initialBuyPrice: string
    grossYield: string
    projectedReturn: string
    forSale: boolean
    network: string
    contractAddress: string
    transactionHash: string
    favorite: boolean
    currency: string
    createdAt: string
    updatedAt: string
    certificate: {
      name: string
      value: string
      _id: string
    }
    unlockAt: string
    __v: 0
  }
  minimumBid: string
  maximumBid: string
  minimumPrice: string
  status: string
  startTime: string
  endTime: string
  createdAt: string
  updatedAt: string
  __v: 0
}

export interface IAuctionResponse extends INetworkResponse {
  data: Array<IAuctionResponseData>
}

export interface ISingleAuctionResponse extends INetworkResponse {
  data: IAuctionResponseData
}

export enum EStatus {
  ACTIVE = 'ACTIVE',
  CONCLUDED = 'CONCLUDED',
  CANCELLED = 'CANCELLED'
}

export interface IBidResponse extends INetworkResponse {
  data: {
    user: string
    auction: string
    property: string
    amount: number
    status: string
    _id: string
    createdAt: string
    updatedAt: string
    __v: 0
    payment: {
      debit: string
      _id: string
    }
  }
}

export interface IBidData {
  amount: number
  currencyCode: string
}

export interface ISaveAuctionResponse extends INetworkResponse {
  data: {
    user: string
    asset: string
    auction: {
      _id: string
      property: string
      minimumBid: number
      maximumBid: number
      minimumPrice: string
      status: string
      startTime: string
      endTime: string
      createdAt: string
      updatedAt: string
      totalBid: number
    }
    _id: string
    createdAt: string
    updatedAt: string
  }
}
