import React, { FC } from 'react'
import { Carousel } from 'react-responsive-carousel'
import { Icon, IconName } from '../Icons'
import { v4 as uuidv4 } from 'uuid'
import { IProperty } from '../../interface/asset'

export interface IPropertyProps {
  id: string
  sold: boolean
  country: string
  images: IProperty['image']
  summary: string
  price: number
  currency: string
  percentageSold?: number
  projectedReturn: string
  grossYield: string
  daysLeft: number
  rentPerMonth: number
  distributionFrequency: string
  status?: string
  onClick: () => void
  askBeforeDelete?: boolean
}

const PropertyCard: FC<IPropertyProps> = ({ sold, ...props }) => {
  const handleClick = (): void => {
    props.onClick()
  }

  return (
    <div className="Property-card">
      <div className="card">
        <div className="Country-status">
          <div className="Country d-flex justify-content-around align-items-center">
            <Icon name={props.country as IconName['name']} />
            <p>{props.country}</p>
          </div>
          {!sold && (
            <div className="Status  d-flex justify-content-around align-items-center">
              <Icon name="profile" />
              {props.status && (
                <p>
                  {`${props.status?.charAt(0).toUpperCase()}${props.status
                    ?.substring(1)
                    .toLowerCase()}`}
                </p>
              )}
            </div>
          )}
        </div>
        <>
          {sold && (
            <div className="Sold">
              <div className="Sold-container d-flex justify-content-around align-items-center">
                <p>sold</p>
              </div>
            </div>
          )}
        </>
        <Carousel showThumbs={false} showStatus={false} autoPlay infiniteLoop>
          {props.images.map((image) => (
            <div key={uuidv4()} className="Slide-image">
              {image?.thumbnail ? <img src={image?.thumbnail.url} /> : null}
            </div>
          ))}
        </Carousel>
        <div className="card-body" onClick={handleClick}>
          <p className="Summary">{props.summary}</p>
          <div className="Price-status d-flex justify-content-between ">
            <p className="Price">
              {props.currency.toUpperCase()} {props.price.toLocaleString()}
            </p>
            <div className="progress align-self-end">
              <div
                className="progress-bar"
                role="progressbar"
                aria-label="investment-progress"
                style={{ width: `${props.percentageSold}%` }}
                aria-valuenow={props.percentageSold}
                aria-valuemin={0}
                aria-valuemax={100}
              />
            </div>

            <p className="Sold-status">{props?.percentageSold ?? 0}% sold</p>
          </div>
          <div className="Info d-flex justify-content-between align-items-center">
            <div>
              <p className="Percentage">{props.projectedReturn}</p>
              <p className="P-label">projected return</p>
            </div>
            <div>
              <p className="Percentage">{props.grossYield}</p>
              <p className="P-label">gross yield</p>
            </div>
            <div>
              <p className="Percentage">{props.daysLeft}</p>
              <p className="P-label">days left</p>
            </div>
          </div>
          <div className="Basic-info d-flex justify-content-between align-items-center">
            <p>
              Rent{' '}
              <strong>
                {props.currency} {props.rentPerMonth.toLocaleString()}/month
              </strong>
            </p>
            <p>
              Distributed{' '}
              <strong>
                {props.distributionFrequency.charAt(0).toUpperCase() +
                  props.distributionFrequency.substring(1).toLowerCase()}
              </strong>
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PropertyCard
