import React from 'react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import { AUTH_ROOT, ROOT } from './navigation'
import { useAuth } from '../store/Auth/auth.provider'

import PrivateRoutes from './PrivateRoutes'
import PublicRoutes from './PublicRoutes'
import { UserProvider } from '../store/User/user.provider'

const Routing: React.FC = () => {
  const { auth } = useAuth()
  return (
    <BrowserRouter>
      <Routes>
        {auth ? (
          <Route
            path={ROOT}
            element={
              <UserProvider>
                <PrivateRoutes />
              </UserProvider>
            }
          />
        ) : (
          <>
            <Route path={AUTH_ROOT} element={<PublicRoutes />} />
            <Route path="*" element={<Navigate to={'auth'} />} />
          </>
        )}
      </Routes>
    </BrowserRouter>
  )
}

export default Routing
