import React, { ReactNode } from 'react'
import './styles.scss'

const Card: React.FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <div className="card">
      <div className="card-body">{children}</div>
    </div>
  )
}

export default Card
