import { fracxnApi } from '../http'
import { handleError } from '../../utils/error'
import { USER_ENDPOINTS } from '../../constants/endpoints'

export const list = async () => {
  try {
    const { data } = await fracxnApi.get(USER_ENDPOINTS.USERS)
    return data
  } catch (error: any) {
    throw new Error(handleError(error))
  }
}

export const find = async (id: string) => {
  try {
    const { data } = await fracxnApi.get(`${USER_ENDPOINTS.USERS}/${id}`)
    return data
  } catch (error: any) {
    throw new Error(handleError(error))
  }
}

export const block = async (id: string) => {
  try {
    const { data } = await fracxnApi.post(`${USER_ENDPOINTS.USERS}/${id}/block`)
    return data
  } catch (error: any) {
    throw new Error(handleError(error))
  }
}

export const unblock = async (id: string) => {
  try {
    const { data } = await fracxnApi.post(
      `${USER_ENDPOINTS.USERS}/${id}/unblock`
    )
    return data
  } catch (error: any) {
    throw new Error(handleError(error))
  }
}

export const listUserInvestments = async (id: string) => {
  try {
    const { data } = await fracxnApi.get(
      `${USER_ENDPOINTS.USERS}/${id}/investments`
    )
    return data
  } catch (error: any) {
    throw new Error(handleError(error))
  }
}

export const findUserInvestment = async (
  userId: string,
  investmentId: string
) => {
  try {
    const { data } = await fracxnApi.get(
      `${USER_ENDPOINTS.USERS}/${userId}/investments/${investmentId}`
    )
    return data
  } catch (error: any) {
    throw new Error(handleError(error))
  }
}

export const listUserBids = async (id: string) => {
  try {
    const { data } = await fracxnApi.get(`${USER_ENDPOINTS.USERS}/${id}/bids`)
    return data
  } catch (error: any) {
    throw new Error(handleError(error))
  }
}

export const findUserBid = async (userId: string, bidId: string) => {
  try {
    const { data } = await fracxnApi.get(
      `${USER_ENDPOINTS.USERS}/${userId}/bids/${bidId}`
    )
    return data
  } catch (error: any) {
    throw new Error(handleError(error))
  }
}

export const listUserDeposits = async (id: string) => {
  try {
    const { data } = await fracxnApi.get(
      `${USER_ENDPOINTS.USERS}/${id}/deposits`
    )
    return data
  } catch (error: any) {
    throw new Error(handleError(error))
  }
}

export const listUserWithdraws = async (id: string) => {
  try {
    const { data } = await fracxnApi.get(
      `${USER_ENDPOINTS.USERS}/${id}/withdraw`
    )
    return data
  } catch (error: any) {
    throw new Error(handleError(error))
  }
}
