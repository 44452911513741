import { TransactionTypeEnum } from '../interface/transaction';

const dashboardMenu = [
  {
    label: 'Dashboard',
    link: '/dashboard',
    icon: 'ri-pie-chart-2-line'
  },
  {
    label: 'User Management',
    link: '/users',
    icon: 'ri-account-circle-line'
  },
  {
    label: 'Asset Management',
    link: '/assets',
    icon: 'ri-suitcase-2-line'
  },
  {
    label: 'Auction Management',
    link: '/auctions',
    icon: 'ri-auction-line'
  },
  {
    label: 'Admin User Management',
    link: '/admins',
    icon: 'ri-user-star-line'
  },
  {
    label: 'Transactions',
    icon: 'ri-money-dollar-circle-line',
    submenu: [
      {
        label: 'Requests',
        link: `/transactions?type=${TransactionTypeEnum.CREDIT}`
      },
      {
        label: 'History',
        link: '/transactions/history'
      }
    ]
  }

];
export {
  dashboardMenu
};
