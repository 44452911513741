export interface IProperty extends IAsset {
  _id: string
  type: string
  title: string
  area: string // 1500 sq.ft
  distributionFrequency?: DistributionFrequency
  distributionFrequencyPrice?: number
  rentPerMonth?: string
  price: number
  createdBy: string // admin
  amenity: AmenityType[] // gym, pool
  description: string // property details
  locationInfo: LocationType // map, country etc
  status: StatusEnum // rented , sold
  specification: NameValueType[] // 2 bedroom |
  image: ImageType[]
  documents: DocumentType
  custom: NameValueType[]
  initialBuyPrice: string // Initial,
  forSale: boolean
  projectedReturn: string
  grossYield: string
  currency: AllCurrencyEnum
  contractAddress: string
  transactionHash: string
  network: string
}

export interface IAsset {
  model: AssetModelEnum
}

export enum AssetModelEnum {
  PROPERTY = 'Property',
  MUSIC = 'Music',
}

export interface AmenityType {
  name: string
  icon: string
}

export enum StatusEnum {
  rent = 'rent',
  investment = 'investment',
  sole = 'sole',
}

export interface FileType {
  url: string
  name?: string
  // code: string,
  // asset_id: string,
  // public_id: string,
  // height: number,
  // width: number,
  // format: string,
  // bytes: number,
}

export interface ImageType {
  thumbnail: FileType
  url: FileType
}

export interface DocumentType {
  ejariUrl: FileType
  dewaUrl: FileType
  titleDeedUrl: FileType
}

export interface NameValueType {
  name: string
  value: string
}

export interface LocationType {
  latitude: number
  longitude: number
  country: string
  state: string
  street: string
  city: string
  zip: string
}

export enum CurrencyEnum {
  AED = 'AED',
  USD = 'USD',
}

export enum AllCurrencyEnum {
  AED = 'AED',
  USD = 'USD',
  USDC = 'USDC',
  USDT = 'USDT',
}

export enum DistributionFrequency {
  MONTHLY = 'MONTHLY',
  SEMI_MONTHLY = 'SEMI_MONTHLY',
  ANNUAL = 'ANNUAL',
  QUARTERLY = 'QUARTERLY',
}

export type PropertyCreationParams = Pick<
  IProperty,
  | 'type'
  | 'title'
  | 'area'
  | 'distributionFrequency'
  | 'rentPerMonth'
  | 'price'
  | 'amenity'
  | 'description'
  | 'locationInfo'
  | 'status'
  | 'specification'
  | 'image'
  | 'documents'
  | 'custom'
  | 'initialBuyPrice'
  | 'forSale'
  | 'projectedReturn'
  | 'grossYield'
  | 'currency'
>
