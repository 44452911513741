import * as AdminService from '../../services/internal/admin.service'
import { ActionType } from '../../interface/auth'
import { handleError } from '../../utils/error'

export const adminActions = {
  LIST_ADMINS: 'LIST_ADMINS',
  BLOCK_ADMIN: 'BLOCK_ADMIN',
  UNBLOCK_ADMIN: 'UNBLOCK_ADMIN',
  ADMIN_ACTION: 'ADMIN_ACTION',
  ADMIN_LOADING: 'ADMIN_LOADING'
}

export const listAdmins = async (): Promise<ActionType> => {
  try {
    const response = await AdminService.list()
    return {
      type: adminActions.LIST_ADMINS,
      payload: response.data
    }
  } catch (error: any) {
    throw new Error(handleError(error))
  }
}

export const blockAdmin = async (id: string): Promise<ActionType> => {
  try {
    const response = await AdminService.block(id)
    return {
      type: adminActions.BLOCK_ADMIN,
      payload: response.data
    }
  } catch (error: any) {
    throw new Error(handleError(error))
  }
}

export const unblockAdmin = async (id: string): Promise<ActionType> => {
  try {
    const response = await AdminService.unblock(id)
    return {
      type: adminActions.UNBLOCK_ADMIN,
      payload: response.data
    }
  } catch (error: any) {
    throw new Error(handleError(error))
  }
}

export const inviteAdmin = async (payload: {
  email: string
  name: string
}): Promise<ActionType> => {
  try {
    const response = await AdminService.invite(payload)
    return {
      type: adminActions.ADMIN_ACTION,
      payload: response.data
    }
  } catch (error: any) {
    throw new Error(handleError(error))
  }
}

export const adminLoadingAction = (payload: boolean) => {
  return {
    type: adminActions.ADMIN_LOADING,
    payload
  }
}
