import React from 'react'
// import './App.scss'
import './scss/style.scss'
import './assets/css/remixicon.css'
import { AuthProvider } from './store/Auth/auth.provider'
import Routing from './routing'
import { ToastContainer } from 'react-toastify'

const App: React.FC = () => {
  return (
    <AuthProvider>
      <ToastContainer />
      <Routing />
    </AuthProvider>
  )
}

export default App
