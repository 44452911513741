import React, { createContext, Dispatch, useReducer, useContext } from 'react'
import { AUTH_INITIAL_STATE, authReducer } from './auth.reducer'
import { ActionType } from '../../interface/auth'

export const AuthStateContext = createContext({ ...AUTH_INITIAL_STATE })
export const AuthDispatchContext = createContext<Dispatch<ActionType>>(() => {})

interface Props {
  children: React.ReactNode
}

// eslint-disable-next-line react/prop-types
const AuthProvider: React.FC<Props> = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, AUTH_INITIAL_STATE)

  return (
    <AuthDispatchContext.Provider value={dispatch}>
      <AuthStateContext.Provider value={state}>
        {children}
      </AuthStateContext.Provider>
    </AuthDispatchContext.Provider>
  )
}

const useAuth = () => useContext(AuthStateContext)

export { AuthProvider, useAuth }
