import * as AuctionService from '../../services/internal/auction.service'
import { ActionType } from '../../interface/auth'
import { handleError } from '../../utils/error'
import {
  CreateAuctionRequest,
  UpdateAuctionRequest
} from '../../interface/auction'

export const auctionActions = {
  CREATE_AUCTION: 'CREATE_AUCTION',
  LIST_AUCTIONS: 'LIST_AUCTIONS',
  LIST_AUCTION_BIDS: 'LIST_AUCTION_BIDS',
  FIND_AUCTION: 'FIND_AUCTION',
  UPDATE_AUCTION: 'UPDATE_AUCTION',
  DELETE_AUCTION: 'DELETE_AUCTION',
  AUCTION_LOADING: 'AUCTION_LOADING'
}

export const listAuctions = async (): Promise<ActionType> => {
  try {
    const response = await AuctionService.list()
    return {
      type: auctionActions.LIST_AUCTIONS,
      payload: response.data
    }
  } catch (error: any) {
    throw new Error(handleError(error))
  }
}

export const findAuction = async (id: string): Promise<ActionType> => {
  try {
    const response = await AuctionService.find(id)
    return {
      type: auctionActions.FIND_AUCTION,
      payload: response.data
    }
  } catch (error: any) {
    throw new Error(handleError(error))
  }
}

export const getAuctionBids = async (id: string): Promise<ActionType> => {
  try {
    const response = await AuctionService.getBids(id)
    return {
      type: auctionActions.LIST_AUCTION_BIDS,
      payload: response.data
    }
  } catch (error: any) {
    throw new Error(handleError(error))
  }
}

export const updateAuction = async (
  id: string,
  payload: UpdateAuctionRequest
): Promise<ActionType> => {
  try {
    const response = await AuctionService.update(id, payload)
    return {
      type: auctionActions.UPDATE_AUCTION,
      payload: response.data
    }
  } catch (error: any) {
    throw new Error(handleError(error))
  }
}

export const deleteAuction = async (id: string): Promise<ActionType> => {
  try {
    const response = await AuctionService.deleteAuction(id)
    return {
      type: auctionActions.DELETE_AUCTION,
      payload: response.data
    }
  } catch (error: any) {
    throw new Error(handleError(error))
  }
}

export const auctionLoading = (payload: boolean) => {
  return {
    type: auctionActions.AUCTION_LOADING,
    payload
  }
}
