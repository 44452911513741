import React, { useContext, useEffect } from 'react'
import PropertyCard from '../../../components/AuctionCard'
import { v4 as uuidv4 } from 'uuid'
import { useNavigate } from 'react-router-dom'
import Card from '../../../components/Card'
import { AUCTIONS } from '../../../routing/navigation'
import Header from '../../../layouts/Header'
import PageTitle from '../../../components/PageTitle'
import {
  AuctionDispatchContext,
  AuctionStateContext
} from '../../../store/Auction/auction.provider'
import {
  auctionLoading,
  findAuction,
  listAuctions
} from '../../../store/Auction/auction.actions'
import { AuctionStatusEnum } from '../../../interface/auction'
import moment from 'moment'
import { IProperty } from '../../../interface/asset'
import { Spinner } from 'react-bootstrap'

const Properties: React.FC = () => {
  const route = useNavigate()

  const { auctions, loading } = useContext(AuctionStateContext)

  const dispatch = useContext(AuctionDispatchContext)
  const temporaryImage: IProperty['image'] = [
    {
      url: {
        name: 'string',
        url: 'assets/images/white-bg.avif'
      },
      thumbnail: {
        url: 'assets/images/white-bg.avif'
      }
    }
  ]

  const getAuctions = async () => {
    dispatch(auctionLoading(true))
    dispatch(await listAuctions())
  }

  useEffect(() => {
    getAuctions()
  }, [])

  return (
    <>
      <div className="main main-app p-3 p-lg-4">
        <Header />
        <PageTitle title="Auctions" />
        <div className="Properties">
          <div className="container">
            <div className="row">
              {loading ? (
                <div className="d-flex justify-content-center">
                  <Spinner animation="grow" />
                </div>
              ) : auctions?.length ? (
                auctions.map((auction) => (
                  <div className="col-md-6 col-lg-4" key={auction._id}>
                    <PropertyCard
                      id={auction._id}
                      onClick={() => route(`/${AUCTIONS}/${auction._id}`)}
                      sold={auction.status === AuctionStatusEnum.CONCLUDED}
                      country={auction.asset?.locationInfo?.country}
                      images={auction.asset?.image ?? temporaryImage}
                      status={auction.status}
                      summary={auction.asset.description}
                      price={Number(auction.asset.price)}
                      currency={auction.asset.currency}
                      percentageSold={auction.percentageFunded}
                      projectedReturn={
                        auction.investorsCount?.toString() ?? '0'
                      }
                      grossYield={auction.asset.grossYield.toString()}
                      daysLeft={moment(auction.endTime).diff(
                        moment(auction.startTime),
                        'days'
                      )}
                      rentPerMonth={Number(auction.asset.rentPerMonth ?? 0)}
                      distributionFrequency={
                        auction.asset.distributionFrequency ?? '0'
                      }
                    />
                  </div>
                ))
              ) : (
                <Card>
                  <div
                    style={{ height: '300px' }}
                    className="card-body d-flex justify-content-center align-items-center"
                  >
                    <h4 className="text-secondary">No Property found</h4>
                  </div>
                </Card>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Properties
