import { fracxnApi } from '../http'
import { handleError } from '../../utils/error'
import { AuctionRequest, UpdateAuctionRequest } from '../../interface/auction'
import { AUCTION_ENDPOINTS } from '../../constants/endpoints'

export const create = async (payload: AuctionRequest) => {
  try {
    const { data } = await fracxnApi.post(AUCTION_ENDPOINTS.AUCTIONS, payload)
    return data
  } catch (error: any) {
    throw new Error(handleError(error))
  }
}

export const list = async () => {
  try {
    const { data } = await fracxnApi.get(AUCTION_ENDPOINTS.AUCTIONS)
    return data
  } catch (error: any) {
    throw new Error(handleError(error))
  }
}

export const find = async (id: string) => {
  try {
    const { data } = await fracxnApi.get(`${AUCTION_ENDPOINTS.AUCTIONS}/${id}`)
    return data
  } catch (error: any) {
    throw new Error(handleError(error))
  }
}

export const getBids = async (id: string) => {
  try {
    const { data } = await fracxnApi.get(
      `${AUCTION_ENDPOINTS.AUCTIONS}/${id}/bids`
    )
    return data
  } catch (error: any) {
    throw new Error(handleError(error))
  }
}

export const update = async (id: string, payload: UpdateAuctionRequest) => {
  try {
    const { data } = await fracxnApi.put(
      `${AUCTION_ENDPOINTS.AUCTIONS}/${id}`,
      payload
    )
    return data
  } catch (error: any) {
    throw new Error(handleError(error))
  }
}

export const deleteAuction = async (id: string) => {
  try {
    const { data } = await fracxnApi.delete(
      `${AUCTION_ENDPOINTS.AUCTIONS}/${id}`
    )
    return data
  } catch (error: any) {
    throw new Error(handleError(error))
  }
}
