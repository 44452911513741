import React, { useContext, useEffect, useState } from 'react'
import { Form, Button, Col, Row, Spinner } from 'react-bootstrap'
import * as Yup from 'yup'
import {
  AllCurrencyEnum,
  PropertyCreationParams,
  StatusEnum,
  LocationType,
  AmenityType,
  DistributionFrequency,
  AssetModelEnum,
  ImageType
} from '../../../interface/asset'
import { useFormik } from 'formik'
import { createAsset } from '../../../store/Assets/asset.actions'
import { AssetDispatchContext } from '../../../store/Assets/asset.provider'
import { toast } from 'react-toastify'
import { titleCase } from '../../../utils/string'
import Dropzone from '../../../components/DropZone'
import {
  removeAssetImages,
  uploadAssetImage
} from '../../../services/internal/asset.service'

interface CreateAssetFormProps {
  onClose: () => void
}
const initialValues: PropertyCreationParams & { assetType: string } = {
  type: '',
  title: '',
  area: '',
  assetType: '',
  distributionFrequency: undefined,
  rentPerMonth: '',
  price: 0,
  amenity: [],
  description: '',
  locationInfo: {
    latitude: 0,
    longitude: 0,
    country: '',
    state: '',
    street: '',
    city: '',
    zip: ''
  } as LocationType,
  status: StatusEnum.sole, // Set default status as Sold, change this according to your requirement
  specification: [],
  image: [],
  documents: {
    ejariUrl: { url: '', name: '' },
    dewaUrl: { url: '', name: '' },
    titleDeedUrl: { url: '', name: '' }
  },
  custom: [
    {
      value: 'string',
      name: 'string'
    }
  ],
  initialBuyPrice: '',
  forSale: false,
  projectedReturn: '',
  grossYield: '',
  currency: AllCurrencyEnum.USD
}

// const initialValues: PropertyCreationParams & { assetType: string } = {
//   type: 'Detached',
//   title: 'Asset2',
//   area: '250sq.ft',
//   assetType: '',
//   distributionFrequency: DistributionFrequency.MONTHLY,
//   rentPerMonth: '100',
//   price: 1100,
//   amenity: [{ name: 'Gym', icon: 'icon-gym' }],
//   description: 'string',
//   locationInfo: {
//     latitude: 25.206736999539466,
//     longitude: 55.275306701660156,
//     country: 'United Arab Emirates',
//     state: 'Dubai',
//     street: 'Trade Centre - DIFC - Dubai - United Arab Emirates',
//     city: 'Dubai',
//     zip: '202011'
//   },
//   status: StatusEnum.rent,
//   specification: [
//     {
//       name: 'Ensuite',
//       value: 'All rooms ensuite'
//     }
//   ],
//   image: [
//     {
//       url: {
//         name: 'string',
//         url: 'string'
//       },
//       thumbnail: {
//         url: 'https://fracxn-dev-assets.s3.us-east-1.amazonaws.com/properties/Property_2.jpg'
//       }
//     }
//   ],
//   documents: {
//     titleDeedUrl: {
//       name: 'string',
//       url: 'string'
//     },
//     dewaUrl: {
//       name: 'string',
//       url: 'string'
//     },
//     ejariUrl: {
//       name: 'string',
//       url: 'string'
//     }
//   },
//   custom: [
//     {
//       value: 'string',
//       name: 'string'
//     }
//   ],
//   initialBuyPrice: '200',
//   forSale: true,
//   projectedReturn: '65%',
//   grossYield: '45%',
//   currency: AllCurrencyEnum.AED
// }
const storedImageHistory: ImageType[] = []

const CreateAssetForm: React.FC<CreateAssetFormProps> = ({ onClose }) => {
  const dispatch = useContext(AssetDispatchContext)

  const [assetImages, setAssetImages] = useState<ImageType[]>([])
  const [uploadedImageHistory, setUploadedImageHistory] = useState<ImageType[]>(
    []
  )

  const assetSchema = Yup.object().shape({
    type: Yup.string().required('Asset Name is required'),
    title: Yup.string().required('Title is required'),
    area: Yup.string()
      .required('Area is required')
      .matches(/^\d+(\.\d+)?\s*sq\.ft$/, {
        message: 'Area must be in the format "1500 sq.ft"',
        excludeEmptyString: true
      }),
    distributionFrequency: Yup.string().required(
      'Distribution Frequency is required'
    ),
    rentPerMonth: Yup.string(),
    price: Yup.number()
      .typeError('Price must be a number')
      .min(0, 'Price must be greater than or equal to 0')
      .required('Price is required'),
    amenity: Yup.array()
      .of(
        Yup.object().shape({
          name: Yup.string().required('amenity name is required'),
          icon: Yup.string()
        })
      )
      .required('Amenity is required')
      .min(1, 'choose at least one amenity'),
    description: Yup.string().required('Description is required'),
    locationInfo: Yup.object().shape({
      latitude: Yup.number().required('Latitude is required'),
      longitude: Yup.number().required('Longitude is required'),
      country: Yup.string().required('Country is required'),
      state: Yup.string().required('State is required'),
      street: Yup.string().required('Street is required'),
      city: Yup.string().required('City is required'),
      zip: Yup.string().required('Zip code is required')
    }),
    status: Yup.string().required('Status is required'),
    specification: Yup.array().of(
      Yup.object().shape({
        name: Yup.string(),
        value: Yup.string().required('Specification value is required')
      })
    ),
    // image: Yup.mixed().required('Image is required'),
    image: Yup.array()
      .of(
        Yup.object().shape({
          url: Yup.object().shape({
            name: Yup.string(),
            url: Yup.string().required('Image URL is required')
          }),
          thumbnail: Yup.object().shape({
            url: Yup.string()
          })
        })
      )
      .required('Image is required')
      .min(1, 'At least one image is required'),
    documents: Yup.object().shape({
      ejariUrl: Yup.object().shape({
        name: Yup.string(),
        url: Yup.string()
      }),
      dewaUrl: Yup.object().shape({
        name: Yup.string(),
        url: Yup.string()
      }),
      titleDeedUrl: Yup.object().shape({
        name: Yup.string(),
        url: Yup.string()
      })
    }),
    custom: Yup.array().of(
      Yup.object().shape({
        name: Yup.string(),
        value: Yup.string()
      })
    ),
    initialBuyPrice: Yup.string().required('Initial Buy Price is required'),
    forSale: Yup.boolean().required('For Sale is required'),
    projectedReturn: Yup.string().required('Projected Return is required'),
    grossYield: Yup.string().required('Gross Yield is required'),
    currency: Yup.string().required('Currency is required')
  })

  const specificationStyle: React.CSSProperties = {
    marginBottom: '16px',
    padding: '8px',
    border: '1px solid #ccc',
    borderRadius: '4px',
    display: 'flex',
    flexDirection: 'column'
  }

  const removeButtonStyle: React.CSSProperties = {
    marginTop: '8px',
    cursor: 'pointer'
  }

  const formik = useFormik({
    initialValues,
    validationSchema: assetSchema,
    onSubmit: async (values, { setStatus, setSubmitting, resetForm }) => {
      try {
        // first submit file and return url responses
        // then add the responses to the formik image props
        setSubmitting(true)
        const { assetType, ...rest } = values
        dispatch(await createAsset(rest))
        const removeUnwantedImages = await removeAssetImages({
          arrayOfAllImages: uploadedImageHistory,
          uploadedImages: assetImages
        })
        onClose()
        toast.success('Asset created successfully')
        setSubmitting(false)
        resetForm()
      } catch (error: any) {
        toast.error(
          error.message ? `${titleCase(error.message)}` : 'Something went wrong'
        )
      }
    }
  })

  const addSpecification = () => {
    formik.setFieldValue('specification', [
      ...formik.values.specification,
      { name: '', value: '' }
    ])
  }

  const removeSpecification = (index: number) => {
    const updatedSpecification = [...formik.values.specification]
    updatedSpecification.splice(index, 1)
    formik.setFieldValue('specification', updatedSpecification)
  }

  const handleImageUpload = async (
    acceptedFiles: Array<File>
  ): Promise<void> => {
    const uploadedImages = []

    for (const file of acceptedFiles) {
      const response = await uploadAssetImage(file)
      if (response) {
        uploadedImages.push(response.data)
        storedImageHistory.push(response.data)
      }
    }
    formik.setFieldValue('image', uploadedImages)
    setAssetImages(uploadedImages)
    setUploadedImageHistory(storedImageHistory)
  }

  useEffect(() => {
    if (formik.isSubmitting) {
      if (formik.errors.locationInfo) {
        const locationInfoErrors = formik.errors.locationInfo as any // Adjust the type as needed
        Object.values(locationInfoErrors).forEach((error) => {
          toast.error(error as string)
        })
      }
      Object.entries(formik.errors).map((field, error) => {
        toast.error(field[1] as string)
        return {}
      })
    }
  }, [formik.isSubmitting])

  const amenitiesData: AmenityType[] = [
    { name: 'none', icon: 'none-icon' },
    { name: 'Gym', icon: 'icon-gym' },
    { name: 'Pool', icon: 'icon-pool' },
    { name: 'Supermarket', icon: 'icon-supermarket' }
    // Add more amenities here
  ]

  return (
    <Form onSubmit={formik.handleSubmit}>
      <Row>
        <Col lg={6} className="mb-3">
          <Form.Label htmlFor="assetType">Asset Type</Form.Label>
          <Form.Select id="assetType" {...formik.getFieldProps('assetType')}>
            <option value=""> Select Type</option>
            {Object.values(AssetModelEnum).map((value) => (
              <option key={value} value={value}>
                {value}
              </option>
            ))}
          </Form.Select>
        </Col>

        {formik.values.assetType === AssetModelEnum.PROPERTY ? (
          <>
            {/* Property creation form code */}
            <Col lg={6} className="mb-3">
              <Form.Label htmlFor="type">Asset Name</Form.Label>
              <Form.Control
                id="type"
                placeholder="Enter asset name"
                {...formik.getFieldProps('type')}
              />
            </Col>
            <Col lg={6} className="mb-3">
              <Form.Label htmlFor="title">Title</Form.Label>
              <Form.Control
                type="text"
                id="title"
                placeholder="Enter other labels"
                {...formik.getFieldProps('title')}
              />
            </Col>

            <Col lg={6} className="mb-3">
              <Form.Label htmlFor="area">Area</Form.Label>
              <Form.Control
                type="text"
                id="title"
                placeholder="Enter other labels"
                {...formik.getFieldProps('area')}
              />
            </Col>

            <Col lg={6} className="mb-3">
              <Form.Label htmlFor="distributionFrequency">
                Distribution Frequency
              </Form.Label>
              <Form.Control
                as="select"
                id="distributionFrequency"
                {...formik.getFieldProps('distributionFrequency')}
              >
                <option value="">Select distribution frequency</option>
                {Object.entries(DistributionFrequency).map(([key, value]) => (
                  <option key={key} value={value}>
                    {key}
                  </option>
                ))}
              </Form.Control>
            </Col>

            <Col lg={6} className="mb-3">
              <Form.Label htmlFor="rentPerMonth">rentPerMonth</Form.Label>
              <Form.Control
                id="rentPerMonth"
                placeholder="Enter other labels"
                {...formik.getFieldProps('rentPerMonth')}
              />
            </Col>

            <Col lg={6} className="mb-3">
              <Form.Label htmlFor="price">price</Form.Label>
              <Form.Control
                id="price"
                type="number"
                placeholder="Enter other labels"
                {...formik.getFieldProps('price')}
              />
            </Col>

            <Col lg={6} className="mb-3">
              <Form.Label htmlFor="amenity">Amenity</Form.Label>
              <Row>
                {amenitiesData.map((amenity) => (
                  <Col lg={3} key={amenity.name}>
                    <Form.Check
                      type="checkbox"
                      name="amenity[]"
                      size={50}
                      onChange={() => {
                        // Toggle the amenity selection
                        const updatedAmenities = formik.values.amenity.some(
                          (selectedAmenity) =>
                            selectedAmenity.name === amenity.name
                        )
                          ? formik.values.amenity.filter(
                              (item) => item.name !== amenity.name
                            )
                          : [...formik.values.amenity, amenity]
                        formik.setFieldValue('amenity', updatedAmenities)
                      }}
                    />
                    {amenity.name}
                  </Col>
                ))}
              </Row>
            </Col>

            <Col lg={6} className="mb-3">
              <Form.Label htmlFor="description">description</Form.Label>
              <Form.Control
                id="description"
                placeholder="Enter description"
                {...formik.getFieldProps('description')}
              />
            </Col>

            <Col lg={3} className="mb-3">
              <Form.Label htmlFor="locationInfo.latitude">Latitude</Form.Label>
              <Form.Control
                id="locationInfo.latitude"
                placeholder="Enter latitude"
                {...formik.getFieldProps('locationInfo.latitude')}
              />
            </Col>

            <Col lg={3} className="mb-3">
              <Form.Label htmlFor="locationInfo.longitude">
                Longitude
              </Form.Label>
              <Form.Control
                id="locationInfo.longitude"
                placeholder="Enter longitude"
                {...formik.getFieldProps('locationInfo.longitude')}
              />
            </Col>

            <Col lg={3} className="mb-3">
              <Form.Label htmlFor="locationInfo.country">Country</Form.Label>
              <Form.Control
                id="locationInfo.country"
                placeholder="Enter country"
                {...formik.getFieldProps('locationInfo.country')}
              />
            </Col>

            <Col lg={3} className="mb-3">
              <Form.Label htmlFor="locationInfo.state">State</Form.Label>
              <Form.Control
                id="locationInfo.state"
                placeholder="Enter state"
                {...formik.getFieldProps('locationInfo.state')}
              />
            </Col>

            <Col lg={3} className="mb-3">
              <Form.Label htmlFor="locationInfo.city">City</Form.Label>
              <Form.Control
                id="locationInfo.city"
                placeholder="Enter city"
                {...formik.getFieldProps('locationInfo.city')}
              />
            </Col>

            <Col lg={3} className="mb-3">
              <Form.Label htmlFor="locationInfo.zip">Zip code</Form.Label>
              <Form.Control
                id="locationInfo.zip"
                placeholder="Enter zip"
                {...formik.getFieldProps('locationInfo.zip')}
              />
            </Col>

            <Col lg={6} className="mb-3">
              <Form.Label htmlFor="locationInfo.street">Street</Form.Label>
              <Form.Control
                id="locationInfo.street"
                placeholder="Enter street"
                {...formik.getFieldProps('locationInfo.street')}
              />
            </Col>

            <Col lg={3} className="mb-3">
              <Form.Label htmlFor="initialBuyPrice">
                Initial Buy Price
              </Form.Label>
              <Form.Control
                id="initialBuyPrice"
                {...formik.getFieldProps('initialBuyPrice')}
              />
            </Col>

            {/* <Col lg={6} className="mb-3">
              <Form.Label htmlFor="custom">Custom</Form.Label>
              <Form.Control id="custom" {...formik.getFieldProps('custom')} />
            </Col> */}

            <Col lg={3} className="mb-3">
              <Form.Label htmlFor="documents.ejariUrl">
                Document ejari Url
              </Form.Label>
              <Form.Control
                id="documents.ejariUrl"
                {...formik.getFieldProps('documents.ejariUrl.url')}
              />
            </Col>

            <Col lg={4} className="mb-3">
              <Form.Label htmlFor="documents.dewaUrl">dewa url</Form.Label>
              <Form.Control
                id="documents.dewaUrl"
                {...formik.getFieldProps('documents.dewaUrl.url')}
              />
            </Col>

            <Col lg={4} className="mb-3">
              <Form.Label htmlFor="documents.titleDeedUrl">
                Document title deed Url
              </Form.Label>
              <Form.Control
                id="documents.titleDeedUrl"
                {...formik.getFieldProps('documents.titleDeedUrl.url')}
              />
            </Col>

            <Col lg={3} className="mb-3">
              <Form.Label htmlFor="projectedReturn">
                Projected Return
              </Form.Label>
              <Form.Control
                id="projectedReturn"
                {...formik.getFieldProps('projectedReturn')}
              />
            </Col>

            <Col lg={3} className="mb-3">
              <Form.Label htmlFor="grossYield">Gross Yield</Form.Label>
              <Form.Control
                id="grossYield"
                {...formik.getFieldProps('grossYield')}
              />
            </Col>

            <Col lg={3} className="mb-3">
              <Form.Label column lg={2} htmlFor="status">
                Status
              </Form.Label>
              <Form.Select
                id="status"
                {...formik.getFieldProps('status')}
                isInvalid={formik.touched.status && !!formik.errors.status}
              >
                <option value="" disabled>
                  Select Status
                </option>
                {Object.entries(StatusEnum).map(([key, value]) => (
                  <option key={key} value={value}>
                    {key}
                  </option>
                ))}
              </Form.Select>
            </Col>

            <Col lg={3} className="mb-3">
              <Form.Label column lg={2} htmlFor="currency">
                Currency
              </Form.Label>
              <Form.Select id="currency" {...formik.getFieldProps('currency')}>
                <option value="">Select Currency</option>
                {Object.entries(AllCurrencyEnum).map(([key, value]) => (
                  <option key={key} value={value}>
                    {key}
                  </option>
                ))}
              </Form.Select>
            </Col>

            <Col lg={3} className="mb-3">
              <Form.Label htmlFor="forSale">For Sale</Form.Label>
              <Form.Check id="forSale" {...formik.getFieldProps('forSale')} />
            </Col>

            <Col lg={12} className="mb-3">
              <Form.Label>Specifications</Form.Label>
              <Row>
                {formik.values.specification.map((spec, index) => (
                  <Col lg={3} key={index} style={specificationStyle}>
                    <Form.Label htmlFor={`specification[${index}].name`}>
                      Name
                    </Form.Label>
                    <Form.Control
                      id={`specification[${index}].name`}
                      name={`specification[${index}].name`}
                      placeholder="Enter Specification Name"
                      value={spec.name}
                      onChange={formik.handleChange}
                    />
                    <Form.Label
                      htmlFor={`specification[${index}].value`}
                      className="mt-3"
                    >
                      Value
                    </Form.Label>
                    <Form.Control
                      id={`specification[${index}].value`}
                      name={`specification[${index}].value`}
                      placeholder="Enter Specification Value"
                      value={spec.value}
                      onChange={formik.handleChange}
                    />
                    {index > 0 && (
                      <Button
                        variant="outline-danger"
                        style={removeButtonStyle}
                        onClick={() => removeSpecification(index)}
                      >
                        Remove
                      </Button>
                    )}
                  </Col>
                ))}
              </Row>
              {formik.values.specification.length < 5 && (
                <Button variant="primary" onClick={addSpecification}>
                  Add Specification
                </Button>
              )}
            </Col>

            <Col lg={6} className="mb-3">
              <Form.Label htmlFor="image">Upload Images</Form.Label>
              <Dropzone onDropAction={handleImageUpload} />
            </Col>

            <div className="d-flex justify-content-end">
              <Button variant="secondary" className="me-2" onClick={onClose}>
                Cancel
              </Button>
              {formik.isSubmitting ? (
                <Spinner animation="grow" />
              ) : (
                <Button type="submit" variant="primary">
                  Create Asset
                </Button>
              )}
            </div>
          </>
        ) : (
          formik.values.assetType === AssetModelEnum.MUSIC && (
            <div className="d-flex justify-content-center align-items-center">
              <img
                src={`${process.env.PUBLIC_URL}/assets/images/coming-soon.png`}
                alt="Coming Soon"
                className="img-fluid"
                style={{ maxWidth: '30%' }}
              />
            </div>
          )
        )}
      </Row>
    </Form>
  )
}

export default CreateAssetForm
