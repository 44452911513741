import React from 'react'
import { Routes, Route } from 'react-router-dom'
import NewPassword from '../pages/SetNewPassword'
import { SIGNIN, FORGOT_PASSWORD, NEW_PASSWORD } from './navigation'
import Signin from '../pages/Signin'
import ForgotPassword from '../pages/ForgotPassword'

const PublicRoutes: React.FC = () => {
  return (
    <Routes>
      <Route>
        <Route path={SIGNIN} element={<Signin />} />
        <Route path={FORGOT_PASSWORD} element={<ForgotPassword />} />
        <Route path={NEW_PASSWORD} element={<NewPassword />} />
        <Route index element={<Signin />} />
      </Route>
    </Routes>
  )
}

export default PublicRoutes
