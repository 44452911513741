import React, { useContext, useEffect, useState } from 'react'
import PageTitle from '../../components/PageTitle'
import Header from '../../layouts/Header'
import { Button, Col, Nav, Row } from 'react-bootstrap'
import { toast } from 'react-toastify'
import Swal from 'sweetalert2'
import {
  TransactionDispatchContext,
  TransactionStateContext
} from '../../store/Transaction/transaction.provider'
import {
  listTransactions,
  transactionLoadingAction
} from '../../store/Transaction/transaction.actions'
import {
  ITransaction,
  TransactionTypeEnum,
  TxnStatusEnum
} from '../../interface/transaction'
import {
  approveTransaction,
  cancelTransaction
} from '../../services/internal/transaction.service'
import { titleCase } from '../../utils/string'
import { TableColumn } from 'react-data-table-component'
import './transactionStyle.css'
import CustomDataTable from '../../components/DataTable'

const emptyState = () => {
  return (
    <div className="d-flex justify-content-center mt-4">
      <h5 className="opacity-50">No request available.</h5>
    </div>
  )
}
const Transactions: React.FC = () => {
  const currentSkin = localStorage.getItem('skin-mode') ? 'dark' : ''

  const { transactions, loading, meta } = useContext(TransactionStateContext)

  const dispatch = useContext(TransactionDispatchContext)

  const [skin, setSkin] = useState(currentSkin)

  const [activeTab, setActiveTab] = useState('deposits')

  const handleTabChange = (tab: string) => {
    setActiveTab(tab)
  }

  const handleApprove = async (
    reference: string,
    userId: string,
    paymentType: TransactionTypeEnum
  ): Promise<void> => {
    Swal.fire({
      title: 'Are you sure you want to approve this transaction?',
      showCancelButton: true,
      confirmButtonText: 'Continue'
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await approveTransaction(reference, { userId, paymentType })
          Swal.fire('Transaction approved!', '', 'success')
          getTransactions()
        } catch (error: any) {
          toast.error(
            error.message
              ? `${titleCase(error.message)}`
              : 'Something went wrong'
          )
        }
      }
    })
  }

  const onTransactionApproveClick = (
    reference: string,
    userId: string,
    paymentType: TransactionTypeEnum
  ): void => {
    handleApprove(reference, userId, paymentType)
  }

  const handleCancel = async (
    reference: string,
    userId: string,
    paymentType: TransactionTypeEnum
  ): Promise<void> => {
    Swal.fire({
      title: 'Are you sure you want to cancel this transaction?',
      showCancelButton: true,
      confirmButtonText: 'Continue'
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await cancelTransaction(reference, { userId, paymentType })
          Swal.fire('Transaction cancelled!', '', 'success')
          getTransactions()
        } catch (error: any) {
          toast.error(
            error.message
              ? `${titleCase(error.message)}`
              : 'Something went wrong'
          )
        }
      }
    })
  }

  const renderStatusCellContent = (row: ITransaction) => {
    const className = `text-${
      row.status === TxnStatusEnum.SUCCESS
        ? 'success'
        : row.status === TxnStatusEnum.PENDING
        ? 'warning'
        : 'danger'
    }`
    return <span className={className}>{row.status}</span>
  }

  const renderActionButtons = (row: ITransaction) => {
    return (
      <div className="d-flex">
        <Button
          variant="primary"
          size="sm"
          className="me-2 text-nowrap"
          onClick={() =>
            onTransactionApproveClick(row.reference, row.user?._id, row.type)
          }
        >
          Approve
        </Button>
        <Button
          variant="danger"
          className="text-nowrap"
          size="sm"
          onClick={() =>
            onTransactionCancelClick(row.reference, row.user?._id, row.type)
          }
        >
          Cancel
        </Button>
      </div>
    )
  }

  const onTransactionCancelClick = (
    reference: string,
    userId: string,
    paymentType: TransactionTypeEnum
  ): void => {
    handleCancel(reference, userId, paymentType)
  }

  const columns: TableColumn<ITransaction>[] = [
    {
      name: 'Reference',
      cell: (row) => `${row.reference}`,
      grow: 2
    },
    {
      name: 'User',
      cell: (row) => (
        <div title={row.user?.name ?? row.user?.email} className="user-cell">
          {row.user?.name ?? row.user?.email}
        </div>
      ),
      grow: 2
    },
    {
      name: 'Amount',
      cell: (row) => `${row.currency} ${row.amount}`
    },
    {
      name: 'Type',
      cell: (row) => row.type
    },
    {
      name: 'Status',
      cell: renderStatusCellContent
    },
    {
      name: 'Source',
      cell: (row) => row.source
    },
    {
      name: 'Category',
      cell: (row) => row.category
    },
    {
      name: 'Actions',
      cell: renderActionButtons,
      grow: 2
    }
  ]

  const switchSkin = (skin: any): void => {
    if (skin === 'dark') {
      const btnWhite = document.getElementsByClassName('btn-white')
      // @ts-expect-error
      for (const btn of btnWhite) {
        btn.classList.add('btn-outline-primary')
        btn.classList.remove('btn-white')
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName(
        'btn-outline-primary'
      )
      // @ts-expect-error
      for (const btn of btnOutlinePrimary) {
        btn.classList.remove('btn-outline-primary')
        btn.classList.add('btn-white')
      }
    }
  }

  const getTransactions = async (page: number = 1, limit: number = 10) => {
    if (activeTab === 'deposits') {
      dispatch(
        await listTransactions({
          type: TransactionTypeEnum.CREDIT,
          status: TxnStatusEnum.PENDING,
          page,
          limit
        })
      )
      return
    }
    if (activeTab === 'withdrawals') {
      dispatch(
        await listTransactions({
          type: TransactionTypeEnum.DEBIT,
          status: TxnStatusEnum.PENDING,
          page,
          limit
        })
      )
    }
  }
  const handlePageChange = (page: number) => {
    getTransactions(page)
  }

  const handlePerRowsChange = (newPerPage: number, page: number): void => {
    getTransactions(page, newPerPage)
  }

  switchSkin(skin)
  useEffect(() => {
    switchSkin(skin)
  }, [skin])

  useEffect(() => {
    dispatch(transactionLoadingAction(true))
    getTransactions()
  }, [activeTab])

  return (
    <div className="main main-app p-3 p-lg-4">
      <Header onSkin={setSkin} />
      <PageTitle title="Transaction Requests" />
      <div className="mb-2">
        <Row>
          <Col xl>
            <Nav className="nav-line mb-4">
              <Nav.Link
                href="#deposits"
                className={activeTab === 'deposits' ? 'active' : ''}
                onClick={() => handleTabChange('deposits')}
              >
                Deposits
              </Nav.Link>
              <Nav.Link
                href="#withdrawals"
                className={activeTab === 'withdrawals' ? 'active' : ''}
                onClick={() => handleTabChange('withdrawals')}
              >
                Withdrawals
              </Nav.Link>
            </Nav>

            <CustomDataTable
              columns={columns}
              data={transactions}
              noDataComponent={emptyState()}
              progressPending={loading}
              persistTableHead
              paginationServer
              paginationTotalRows={meta?.total ?? 0}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
            />
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default Transactions
