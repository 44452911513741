import React from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import Dashboard from '../pages/Dashboard'
import User from '../pages/Dashboard/UserManagement'
import UserDetails from '../pages/Dashboard/UserManagement/show'
import AssetManagement from '../pages/Dashboard/AssetManagement'
import Auctions from '../pages/Dashboard/Auctions'
import Main from '../layouts/Main'
import { AssetProvider } from '../store/Assets/asset.provider'
import Notfound from '../pages/404'
import {
  ADMINS,
  ASSETS,
  AUCTIONS,
  AUTH_ROOT,
  DASHBOARD,
  TRANSACTIONS,
  TRANSACTION_HISTORY,
  USERS,
  USER_DETAILS
} from './navigation'
import AssetDetails from '../pages/Dashboard/AssetManagement/show'
import Transactions from '../pages/Transaction'
import { TransactionProvider } from '../store/Transaction/transaction.provider'
import { AuctionProvider } from '../store/Auction/auction.provider'
import AuctionDetails from '../pages/Dashboard/Auctions/show'
import TransactionHistory from '../pages/Transaction/history'
import AdminManagement from '../pages/Admin'
import { AdminProvider } from '../store/Admin/admin.provider'

const PrivateRoute: React.FC = () => {
  return (
    <Routes>
      <Route element={<Main />}>
        <Route index element={<Navigate to={DASHBOARD} />} />
        <Route path={AUTH_ROOT} element={<Navigate to="/dashboard" />} />
        <Route path={DASHBOARD} element={<DashboardWithProviders />} />
        <Route path={USERS} element={<User />} />
        <Route path={USER_DETAILS} element={<UserDetails />} />
        <Route
          path={ADMINS}
          element={
            <AdminProvider>
              <AdminManagement />
            </AdminProvider>
          }
        />
        <Route
          path={ASSETS}
          element={
            <AssetProvider>
              <AssetManagement />
            </AssetProvider>
          }
        />
        <Route
          path={`${ASSETS}/:id`}
          element={
            <AssetProvider>
              <AssetDetails />
            </AssetProvider>
          }
        />
        <Route
          path={AUCTIONS}
          element={
            <AuctionProvider>
              <Auctions />
            </AuctionProvider>
          }
        />
        <Route
          path={`${AUCTIONS}/:id`}
          element={
            <AuctionProvider>
              <AuctionDetails />
            </AuctionProvider>
          }
        />

        <Route
          path={TRANSACTIONS}
          element={
            <TransactionProvider>
              <Transactions />
            </TransactionProvider>
          }
        />

        <Route
          path={`${TRANSACTION_HISTORY}`}
          element={
            <TransactionProvider>
              <TransactionHistory />
            </TransactionProvider>
          }
        />

        <Route path="*" element={<Notfound />} />
      </Route>
    </Routes>
  )
}

const DashboardWithProviders: React.FC = () => {
  return (
    <AssetProvider>
      <TransactionProvider>
        <Dashboard />
      </TransactionProvider>
    </AssetProvider>
  )
}

export default PrivateRoute
