import React from 'react'
import { Form, Spinner } from 'react-bootstrap'
import DataTable, {
  TableColumn,
  IDataTableProps
} from 'react-data-table-component'

interface CustomDataTableProps extends IDataTableProps<any> {
  columns: TableColumn<any>[]
  data: any[]
}

const CustomDataTable: React.FC<CustomDataTableProps> = ({
  columns,
  data,
  ...props
}) => {
  const customStyles = {
    head: {
      style: {
        fontSize: '14px',
        fontWeight: 700
      }
    }
  }

  return (
    <DataTable
      columns={columns}
      data={data}
      highlightOnHover
      fixedHeader
      striped
      pagination
      customStyles={customStyles}
      progressComponent={<Spinner animation="grow" className="mt-4" />}
      {...props}
    />
  )
}

export default CustomDataTable
