import React, { useContext, useEffect, useState } from 'react'
import Table from '../../components/Table'

import PageTitle from '../../components/PageTitle'
import Header from '../../layouts/Header'
import { Button, Row } from 'react-bootstrap'
import ModalComponent from '../../components/Modal'
import {
  AdminDispatchContext,
  AdminStateContext
} from '../../store/Admin/admin.provider'
import {
  adminLoadingAction,
  blockAdmin,
  listAdmins,
  unblockAdmin
} from '../../store/Admin/admin.actions'
import Swal from 'sweetalert2'
import { toast } from 'react-toastify'
import { AdminStatusEnum, IAdmin } from '../../interface/admin'
import CustomDataTable from '../../components/DataTable'
import { TableColumn } from 'react-data-table-component'
import { AuthStateContext } from '../../store/Auth/auth.provider'
import InviteAdminForm from './invite'

const AdminManagement: React.FC = () => {
  const { admins, loading } = useContext(AdminStateContext)

  const dispatch = useContext(AdminDispatchContext)

  const currentSkin = localStorage.getItem('skin-mode') ? 'dark' : ''

  const [skin, setSkin] = useState(currentSkin)
  const [showInviteAdminModal, setShowInviteAdminModal] = useState(false)
  const { user } = useContext(AuthStateContext)

  const handleBlockAdmin = async (id: string): Promise<void> => {
    try {
      Swal.fire({
        title: 'Are you sure you want to block this admin?',
        showCancelButton: true,
        confirmButtonText: 'Continue'
      }).then(async (result) => {
        if (result.isConfirmed) {
          Swal.fire('Admin blocked!', '', 'success')
          dispatch(await blockAdmin(id))
          getAdmins()
        }
      })
    } catch (error) {
      toast.error('Something went wrong')
    }
  }

  const handleUnblockAdmin = async (id: string) => {
    try {
      dispatch(await unblockAdmin(id))
      toast.success('Admin unblocked')
      getAdmins()
    } catch (error) {
      toast.error('Something went wrong')
    }
  }

  const onBlockAdminClick = (id: string): void => {
    handleBlockAdmin(id)
  }

  const onUnblockAdminClick = (id: string): void => {
    handleUnblockAdmin(id)
  }

  const renderAdminActionsCell = (row: IAdmin) => {
    if (row._id === user?._id) {
      return null
    }
    return row.status === AdminStatusEnum.ACTIVE ? (
      <Button
        variant="danger"
        size="sm"
        onClick={() => onBlockAdminClick(row._id)}
      >
        Block
      </Button>
    ) : (
      <Button
        variant="primary"
        size="sm"
        onClick={() => onUnblockAdminClick(row._id)}
      >
        Unblock
      </Button>
    )
  }

  const renderStatusCell = (row: IAdmin) => {
    const className = `text-${
      row.status === AdminStatusEnum.ACTIVE
        ? 'success'
        : row.status === AdminStatusEnum.BLOCKED
        ? 'danger'
        : 'warning'
    }`
    return <span className={className}>{row.status}</span>
  }

  const columns: TableColumn<IAdmin>[] = [
    {
      name: 'Email',
      cell: (row) => row.email
    },
    {
      name: 'Name',
      cell: (row) => row.name
    },
    {
      name: 'Phone',
      cell: (row) => row.phone ?? '--'
    },
    {
      name: 'Status',
      cell: renderStatusCell
    },
    {
      name: 'Actions',
      cell: renderAdminActionsCell
    }
  ]

  const switchSkin = (skin: any): void => {
    if (skin === 'dark') {
      const btnWhite = document.getElementsByClassName('btn-white')
      // @ts-expect-error
      for (const btn of btnWhite) {
        btn.classList.add('btn-outline-primary')
        btn.classList.remove('btn-white')
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName(
        'btn-outline-primary'
      )
      // @ts-expect-error
      for (const btn of btnOutlinePrimary) {
        btn.classList.remove('btn-outline-primary')
        btn.classList.add('btn-white')
      }
    }
  }

  const toggleInviteAdminModal = (): void => {
    setShowInviteAdminModal((prev) => !prev)
  }

  const getAdmins = async () => {
    dispatch(adminLoadingAction(true))
    dispatch(await listAdmins())
  }

  switchSkin(skin)
  useEffect(() => {
    switchSkin(skin)
  }, [skin])

  useEffect(() => {
    getAdmins()
  }, [])

  return (
    <div className="main main-app p-3 p-lg-4">
      <Header onSkin={setSkin} />
      <div className="d-sm-flex align-items-center justify-content-between mb-4">
        <PageTitle title="Admins" />
        <div className="d-flex align-items-center gap-2 mt-3 mt-md-0">
          <Button
            variant="primary"
            className="d-flex align-items-center gap-2"
            onClick={toggleInviteAdminModal}
          >
            <i className="ri-user-add-line fs-18 lh-1"></i>Invite Admin
          </Button>
        </div>
      </div>
      <CustomDataTable
        columns={columns}
        data={admins}
        progressPending={loading}
        persistTableHead
      />

      <ModalComponent
        handleClose={toggleInviteAdminModal}
        show={showInviteAdminModal}
        title="Invite Admin"
        size="sm"
      >
        <InviteAdminForm onClose={toggleInviteAdminModal} />
      </ModalComponent>
    </div>
  )
}

export default AdminManagement
