/* eslint-disable no-return-assign */
/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { Component, createRef } from 'react'
import { Link, NavLink } from 'react-router-dom'
import PerfectScrollbar from 'react-perfect-scrollbar'
import userAvatar from '../assets/img/img1.jpg'
import { dashboardMenu } from '../data/Menu'
import { AuthStateContext } from '../store/Auth/auth.provider'

export default class Sidebar extends Component {
  toggleFooterMenu = (e: any) => {
    e.preventDefault()

    const parent = e.target.closest('.sidebar')
    parent.classList.toggle('footer-menu-show')
  }

  _scrollBarRef = createRef<PerfectScrollbar>()

  render() {
    return (
      <div className="sidebar">
        <div className="sidebar-header">
          <Link to="/" className="sidebar-logo">
            Fracxn
          </Link>
        </div>
        <PerfectScrollbar className="sidebar-body" ref={this._scrollBarRef}>
          <SidebarMenu
            onUpdateSize={() => this._scrollBarRef.current?.updateScroll()}
          />
        </PerfectScrollbar>
        <div className="sidebar-footer">
          <div className="sidebar-footer-top">
            <div className="sidebar-footer-thumb">
              <img src={userAvatar} alt="" />
            </div>
            <div className="sidebar-footer-body">
              <AuthStateContext.Consumer>
                {(value) => {
                  const { user } = value
                  return (
                    <h6>
                      <Link to="#pages/profile.html">{user?.email}</Link>
                    </h6>
                  )
                }}
              </AuthStateContext.Consumer>
              <p>Administrator</p>
            </div>
            <Link
              onClick={this.toggleFooterMenu}
              to=""
              className="dropdown-link"
            >
              <i className="ri-arrow-down-s-line"></i>
            </Link>
          </div>
          <div className="sidebar-footer-menu">
            <nav className="nav">
              <Link to="">
                <i className="ri-edit-2-line"></i> Edit Profile
              </Link>
              <Link to="">
                <i className="ri-logout-box-r-line"></i> Log Out
              </Link>
            </nav>
          </div>
        </div>
      </div>
    )
  }
}

class SidebarMenu extends Component<{ onUpdateSize: () => void }> {
  populateMenu = (m: any) => {
    const menu = m.map((m: any, key: any) => {
      let sm
      if (m.submenu) {
        sm = m.submenu.map((sm: any, key: any) => {
          return (
            <NavLink to={sm.link} className="nav-sub-link" end key={key}>
              {sm.label}
            </NavLink>
          )
        })
      }

      return (
        <li key={key} className="nav-item">
          {!sm ? (
            <NavLink to={m.link} className="nav-link">
              <i className={m.icon}></i> <span>{m.label}</span>
            </NavLink>
          ) : (
            <div onClick={this.toggleSubMenu} className="nav-link has-sub">
              <i className={m.icon}></i> <span>{m.label}</span>
            </div>
          )}
          {m.submenu && <nav className="nav nav-sub">{sm}</nav>}
        </li>
      )
    })

    return <ul className="nav nav-sidebar">{menu}</ul>
  }

  // Toggle menu group
  toggleMenu = (e: any) => {
    e.preventDefault()

    const parent = e.target.closest('.nav-group')
    parent.classList.toggle('show')
    this.props.onUpdateSize()
  }

  // Toggle submenu while closing siblings' submenu
  toggleSubMenu = (e: any) => {
    e.preventDefault()

    const parent = e.target.closest('.nav-item')
    let node = parent.parentNode.firstChild

    while (node) {
      if (node !== parent && node.nodeType === Node.ELEMENT_NODE) {
        node.classList.remove('show')
      }
      node = node.nextElementSibling || node.nextSibling
    }

    parent.classList.toggle('show')
    this.props.onUpdateSize()
  }

  render() {
    return (
      <React.Fragment>
        <div className="nav-group show">{this.populateMenu(dashboardMenu)}</div>
      </React.Fragment>
    )
  }
}

window.addEventListener('click', function (e) {
  // Close sidebar footer menu when clicked outside of it
  const tar = e.target as any
  const sidebar = document.querySelector('.sidebar')
  if (!tar?.closest('.sidebar-footer') && sidebar) {
    sidebar.classList.remove('footer-menu-show')
  }

  // Hide sidebar offset when clicked outside of sidebar

  if (!tar?.closest('.sidebar') && !tar.closest('.menu-link')) {
    document?.querySelector('body')?.classList.remove('sidebar-show')
  }
})

window.addEventListener('load', function () {
  const skinMode = localStorage.getItem('sidebar-skin')
  const HTMLTag = document.querySelector('html')

  if (skinMode) {
    HTMLTag?.setAttribute('data-sidebar', skinMode)
  }
})
