/* eslint-disable @typescript-eslint/restrict-template-expressions */
export const capitalize = (text: string): string =>
  text.charAt(0).toUpperCase() + text.substring(1).toLowerCase()

export const getQueryString = (data: { [key: string]: any }): string => {
  const keys = Object.keys(data)
  let newString = ''
  keys.forEach((key, index) => {
    if (index === 0) {
      if (data[key]) {
        newString = `?${key}=${data[key]}`
      }
    } else if (data[key] !== undefined && data[key] !== null) {
      if (newString) {
        newString = `${newString}&${key}=${data[key]}`
      } else {
        newString = `?${key}=${data[key]}`
      }
    }
  })
  return newString
}

export function buildPageQuery(
  pageQuery?: Record<string, string | boolean | number>,
  appendQuestionMark = true
) {
  if (!pageQuery) return ''

  let query = Object.keys(pageQuery)
    .reduce<string[]>((queryArray, key) => {
      let value = pageQuery[key]
      if (typeof value === 'string') {
        value = value.trim()
      }

      if ((value || typeof value === 'boolean') && value !== '') {
        queryArray.push(`${key}=${value}`)
      }

      return queryArray
    }, [])
    .join('&')

  if (appendQuestionMark && query && query !== '') {
    query = '?' + query
  }

  return query
}

/**
 * Converts snake case to title case
 * @param string
 * @returns Title case
 */
export const titleCase = (s: string) =>
  s
    .replace(/^[-_]*(.)/, (_, c: string) => c.toUpperCase()) // Initial char (after -/_)
    .replace(/[-_]+(.)/g, (_, c: string) => ' ' + c.toUpperCase())
