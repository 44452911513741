import { AllCurrencyEnum } from './asset'
import { INetworkResponse } from './network'
import { IUser } from './user'

export interface ITransaction {
  _id: string
  reference: string
  user: IUser
  amount: number
  status: TxnStatusEnum
  type: TransactionTypeEnum
  category: CategoryEnum
  categoryId: string
  remark: string
  currency?: AllCurrencyEnum
  currencyType?: AllCurrencyTypeEnum
  source?: SourceEnum
  sourceId?: string
  gatewayMessage?: string
  swap?: boolean
  swapCurrency?: AllCurrencyEnum
  swapRate?: number
  swapValue?: number
  swapRateSpread?: number
  swapValueSpread?: number
  fee?: number
  createdAt?: Date
  updatedAt?: Date
}

export interface ITransactionsResponse extends INetworkResponse {
  data: Array<{
    _id: string
    user: string
    amount: number
    currency: string
    type: string
    category: string
    categoryId: string
    remark: string
    status: string
    createdAt: string
    updatedAt: string
  }>
}

export interface IVerifyCardTransaction extends INetworkResponse {
  data: {
    amount: number
    category: string
    createdAt: string
    currency: string
    currencyType: string
    gatewayMessage: string
    reference: string
    remark: string
    source: string
    sourceId: string
    status: string
    type: string
    updatedAt: string
    user: string
  }
}

export interface IWireDepositData {
  from: {
    swiftCode: string
    country: string
    iban: string
    accountNumber: string
    bankName: string
    accountName: string
  }
  amount: number
  currencyCode: string
}

export interface IWireWithdrawalData {
  remark: string
  beneficiaryReference: string
  amount: number
  currencyCode: string
}

export interface IGetTransactions {
  type?: string
  status?: string
  currencyType?: string
}

export enum ETransactionType {
  DEBIT = 'DEBIT',
  CREDIT = 'CREDIT'
}

export enum ECurrencyType {
  FIAT = 'FIAT',
  CRYPTO = 'CRYPTO'
}

export enum ETransactionStatus {
  FAILED = 'FAILED',
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESS'
}

export interface IWireDepositResponseData {
  transactionId: string
  reference: string
  amount: string
  type: string
  status: string
  paymentReference: string
  createdAt: string
  updatedAt: string
  currency: string
  from: {
    accountName: string
    bankName: string
    accountNumber: string
    iban: string
    country: string
    swiftCode: string
  }
  to: {
    accountName: string
    bankName: string
    accountNumber: string
    iban: string
    country: string
    swiftCode: string
  }
}

export enum TxnCurrencyEnum {
  AED = 'AED',
  USD = 'USD'
}

export enum TxnCryptoCurrencyEnum {
  USDC = 'USDC',
  USDT = 'USDT'
}
export enum TxnStatusEnum {
  FAILED = 'FAILED',
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESS',
  CANCELLED = 'CANCELLED',
  REVERSED = 'REVERSED'
}

export enum TransactionEnum {
  DEBIT = 'DEBIT',
  CREDIT = 'CREDIT'
}

export enum CategoryEnum {
  BID = 'BID',
  INFLOW = 'INFLOW',
  OUTFLOW = 'OUTFLOW'
}

export enum SourceEnum {
  CARD = 'CARD',
  WIRE = 'WIRE',
  CRYPTO = 'CRYPTO'
}

export enum AllCurrencyTypeEnum {
  FIAT = 'FIAT',
  CRYPTO = 'CRYPTO'
}

export enum InvestmentStatusEnum {
  PENDING = 'PENDING',
  LOCKED = 'LOCKED',
  ACTIVE = 'ACTIVE',
  REFUNDED = 'REFUNDED',
  CANCELED = 'CANCELED'
}

export enum TransactionTypeEnum {
  DEBIT = 'DEBIT',
  CREDIT = 'CREDIT'
}
