import React, { useEffect, useContext } from 'react'

import PageTitle from '../../../components/PageTitle'
import Header from '../../../layouts/Header'
import { Card, Button, Spinner } from 'react-bootstrap'
import { useParams, Link } from 'react-router-dom'
import {
  AssetDispatchContext,
  AssetStateContext
} from '../../../store/Assets/asset.provider'
import {
  assetLoadingAction,
  findAsset
} from '../../../store/Assets/asset.actions'
import { toast } from 'react-toastify'
import { Icon, IconName } from '../../../components/Icons'
import Map from '../../../components/Map'
import PropertyPhotoList from '../../../components/AuctionPhotoList'

const AssetDetails: React.FC = () => {
  const { id } = useParams<{ id: string }>()

  const { asset, loading } = useContext(AssetStateContext)

  const dispatch = useContext(AssetDispatchContext)

  const handleFindAsset = async (id: string) => {
    try {
      dispatch(await findAsset(id))
    } catch (error: any) {
      toast.error(error)
    }
  }

  useEffect(() => {
    if (id) {
      dispatch(assetLoadingAction(true))
      handleFindAsset(id)
    }
  }, [id])

  return (
    <div className="main main-app p-3 p-lg-4">
      <Header />
      <PageTitle title={`${asset?.title} details` || 'Asset details'} />
      <div
        className="Property-details pb-5"
        style={!asset ? { height: '100vh' } : {}}
      >
        {loading ? (
          <div className="d-flex justify-content-center">
            <Spinner animation="grow" />
          </div>
        ) : (
          asset && (
            <div className="container">
              <PropertyPhotoList images={asset.image} />

              <div className="row">
                <div className="Save-photo col-12 d-flex justify-content-end mt-4">
                  <div className="ms-4">
                    <Button
                      variant="primary"
                      // onClick={}
                    >
                      Photos
                    </Button>
                  </div>
                </div>
              </div>
              <section className="Detail-section">
                <h2>{asset.title || asset.type}</h2>
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    {asset.specification.map((spec: any) => (
                      <li
                        key={spec._id}
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        {spec.value} {spec.name}
                      </li>
                    ))}

                    <li className="breadcrumb-item active" aria-current="page">
                      {asset.area}
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      {asset.locationInfo.state}
                    </li>
                  </ol>
                </nav>

                <div className="row">
                  <div className="col-lg-8">
                    <div className="mb-5">
                      <Card>
                        <div className="card-body d-flex">
                          <div className="align-self-center me-4">
                            <Icon name="dubai-large" />
                          </div>
                          <div>
                            <h3>
                              {asset.locationInfo.country},{' '}
                              {asset.locationInfo.state}
                            </h3>
                            <small>
                              A high demand real estate market with great return
                              on investment.
                            </small>
                          </div>
                        </div>
                        {asset.status === 'rent' && (
                          <div className="card-body d-flex">
                            <div className="align-self-center me-4">
                              <Icon name="rented" />
                            </div>
                            <div>
                              <h3>{asset.status}</h3>
                              <small>For know rented and managed by us.</small>
                            </div>
                          </div>
                        )}
                        <div className="card-body d-flex">
                          <div className="align-self-center me-4">
                            <Icon name="coins" />
                          </div>
                          <div>
                            <h3>
                              Current rent is {asset.currency}{' '}
                              {Number(asset.price).toLocaleString()} per month{' '}
                            </h3>
                            <small>
                              Distributed motnly among the investors after
                              deducting the charges and fees
                            </small>
                          </div>
                        </div>
                      </Card>
                    </div>

                    <div className="Prop-details">
                      <h2 className="mb-4">Property Details</h2>
                      <p>{asset.description}</p>
                    </div>

                    <div className="Location">
                      <h2 className="mb-3">Location</h2>
                      <Map
                        location={{
                          lat: asset.locationInfo.latitude,
                          lng: asset.locationInfo.longitude,
                          address: `${asset.locationInfo.street} ${asset.locationInfo.city} ${asset.locationInfo.state} ${asset.locationInfo.country}`
                        }}
                        zoomLevel={20}
                      />
                      <p>
                        {`${asset.locationInfo.street} ${asset.locationInfo.city} ${asset.locationInfo.state} ${asset.locationInfo.country}`}
                      </p>
                    </div>

                    <div className="Amenities">
                      <h2 className="mb-3">Amenities</h2>
                      <div className="d-flex align-items-center flex-wrap">
                        {asset.amenity.map((amenity: any) => (
                          <div className="Flex mb-5" key={amenity._id}>
                            <Icon name={amenity.icon as IconName['name']} />
                            <span>{amenity.name}</span>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          )
        )}
      </div>
    </div>
  )
}

export default AssetDetails
