import React, { FC, useContext } from 'react'
import { Button, Card, Col, Form, Row } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import { FORGOT_PASSWORD } from '../../routing/navigation'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { AuthDispatchContext } from '../../store/Auth/auth.provider'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ActionType } from '../../interface/auth'
import { loginAction, getAuthUserAction } from '../../store/Auth/auth.actions'
import { toast } from 'react-toastify'
import { titleCase } from '../../utils/string'

const Signin: FC = () => {
  const loginSchema = Yup.object().shape({
    email: Yup.string()
      .email('Wrong email format')
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required('Email is required'),
    password: Yup.string()
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required('Password is required')
  })
  const dispatch = useContext(AuthDispatchContext)
  const navigate = useNavigate()
  const initialValues = {
    email: '',
    password: ''
  }

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      try {
        dispatch(await loginAction(values))
        toast.success('Login successful')
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        setTimeout(async () => {
          dispatch(await getAuthUserAction())
          navigate('/dashboard')
        }, 1000)
      } catch (error: any) {
        console.log({ error })

        toast.error(
          error.message ? `${titleCase(error.message)}` : 'Something went wrong'
        )
      }
    }
  })

  return (
    <div className="page-sign">
      <Card className="card-sign">
        <Card.Header>
          <Link to="/" className="header-logo mb-4">
            Fracxn
          </Link>
          <Card.Title>Sign In</Card.Title>
          <Card.Text>Welcome back! Please signin to continue.</Card.Text>
        </Card.Header>
        <Card.Body>
          <Form method="get" onSubmit={formik.handleSubmit}>
            <div className="mb-4">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                {...formik.getFieldProps('email')}
                placeholder="Enter your email address"
              />
            </div>
            <div className="mb-4">
              <Form.Label className="d-flex justify-content-between">
                Password{' '}
                <Link to={`/${FORGOT_PASSWORD}`}>Forgot password?</Link>
              </Form.Label>
              <Form.Control
                {...formik.getFieldProps('password')}
                type="password"
                placeholder="Enter your password"
              />
            </div>
            <Button type="submit" variant="primary" className="btn-sign">
              Sign In
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </div>
  )
}

export default Signin
