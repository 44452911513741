export interface IAdmin {
  _id: string
  name: string
  email: string
  status: AdminStatusEnum
  phone?: string
  isDeleted?: boolean
  createdAt: string
  updatedAt: string
}

export enum AdminStatusEnum {
  ACTIVE = 'active',
  'INVITE-SENT' = 'invite-sent',
  BLOCKED = 'blocked'
}
