/* eslint-disable react/display-name */
import React, { FC, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Dropdown from 'react-bootstrap/Dropdown'
import userAvatar from '../assets/img/img1.jpg'
import notification from '../data/Notification'
import { logout } from '../services/internal/auth.service'
import { clearStorage } from '../utils/storage'
import { AuthStateContext } from '../store/Auth/auth.provider'

const Header: FC<{ onSkin?: any }> = ({ ...props }) => {
  const currentSkin = localStorage.getItem('skin-mode') ? 'dark' : ''
  const navigate = useNavigate()

  const [skin, setSkin] = useState(currentSkin)
  const switchSkin = (skin: any): void => {
    if (skin === 'dark') {
      const btnWhite = document.getElementsByClassName('btn-white')
      // @ts-expect-error
      for (const btn of btnWhite) {
        btn.classList.add('btn-outline-primary')
        btn.classList.remove('btn-white')
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName(
        'btn-outline-primary'
      )
      // @ts-expect-error
      for (const btn of btnOutlinePrimary) {
        btn.classList.remove('btn-outline-primary')
        btn.classList.add('btn-white')
      }
    }
  }

  const handleLogout = async () => {
    await logout()
    clearStorage()
    setTimeout(() => {
      location.reload()
    }, 1000)
  }

  const onLogoutClick = () => {
    handleLogout()
  }

  switchSkin(skin)
  useEffect(() => {
    switchSkin(skin)
  }, [skin])

  const CustomToggle = React.forwardRef<any, any>(
    ({ children, onClick }, ref) => (
      <Link
        to=""
        ref={ref}
        onClick={(e) => {
          e.preventDefault()
          onClick(e)
        }}
        className="dropdown-link"
      >
        {children}
      </Link>
    )
  )

  const toggleSidebar = (e: any): void => {
    e.preventDefault()
    const isOffset = document.body.classList.contains('sidebar-offset')
    if (isOffset) {
      document.body.classList.toggle('sidebar-show')
    } else {
      if (window.matchMedia('(max-width: 991px)').matches) {
        document.body.classList.toggle('sidebar-show')
      } else {
        document.body.classList.toggle('sidebar-hide')
      }
    }
  }

  function NotificationList(): JSX.Element {
    const notiList = notification.map((item, key) => {
      return (
        <li className="list-group-item" key={key}>
          <div
            className={item.status === 'online' ? 'avatar online' : 'avatar'}
          >
            {item.avatar}
          </div>
          <div className="list-group-body">
            <p>{item.text}</p>
            <span>{item.date}</span>
          </div>
        </li>
      )
    })

    return <ul className="list-group">{notiList}</ul>
  }

  const skinMode = (e: any): void => {
    e.preventDefault()
    e.target.classList.add('active')

    let node = e.target.parentNode.firstChild
    while (node) {
      if (node !== e.target && node.nodeType === Node.ELEMENT_NODE) {
        node.classList.remove('active')
      }
      node = node.nextElementSibling || node.nextSibling
    }

    const skin = e.target.textContent.toLowerCase()
    const HTMLTag = document.querySelector('html')

    if (skin === 'dark') {
      HTMLTag?.setAttribute('data-skin', skin)
      localStorage.setItem('skin-mode', skin)

      if (props.onSkin) {
        props.onSkin(skin)
      } else {
        setSkin(skin)
      }
    } else {
      HTMLTag?.removeAttribute('data-skin')
      localStorage.removeItem('skin-mode')

      if (props.onSkin) {
        props.onSkin('')
      } else {
        setSkin('')
      }
    }
  }

  const sidebarSkin = (e: any): void => {
    e.preventDefault()
    e.target.classList.add('active')

    let node = e.target.parentNode.firstChild
    while (node) {
      if (node !== e.target && node.nodeType === Node.ELEMENT_NODE) {
        node.classList.remove('active')
      }
      node = node.nextElementSibling || node.nextSibling
    }

    const skin = e.target.textContent.toLowerCase()
    const HTMLTag = document.querySelector('html')

    HTMLTag?.removeAttribute('data-sidebar')

    if (skin !== 'default') {
      HTMLTag?.setAttribute('data-sidebar', skin)
      localStorage.setItem('sidebar-skin', skin)
    } else {
      localStorage.removeItem('sidebar-skin')
    }
  }

  return (
    <div className="header-main px-3 px-lg-4">
      <Link onClick={toggleSidebar} className="menu-link me-3 me-lg-4" to={''}>
        <i className="ri-menu-2-fill"></i>
      </Link>

      <div className="form-search me-auto">
        <input type="text" className="form-control" placeholder="Search" />
        <i className="ri-search-line"></i>
      </div>

      <Dropdown className="dropdown-skin" align="end">
        <Dropdown.Toggle as={CustomToggle}>
          <i className="ri-settings-3-line"></i>
        </Dropdown.Toggle>
        <Dropdown.Menu className="mt-10-f">
          <label>Skin Mode</label>
          <nav className="nav nav-skin">
            <Link
              onClick={skinMode}
              className={
                localStorage.getItem('skin-mode')
                  ? 'nav-link'
                  : 'nav-link active'
              }
              to={''}
            >
              Light
            </Link>
            <Link
              onClick={skinMode}
              className={
                localStorage.getItem('skin-mode')
                  ? 'nav-link active'
                  : 'nav-link'
              }
              to={''}
            >
              Dark
            </Link>
          </nav>
          <hr />
          <label>Sidebar Skin</label>
          <nav id="sidebarSkin" className="nav nav-skin">
            <Link
              onClick={sidebarSkin}
              className={
                !localStorage.getItem('sidebar-skin')
                  ? 'nav-link active'
                  : 'nav-link'
              }
              to={''}
            >
              Default
            </Link>
            <Link
              onClick={sidebarSkin}
              className={
                localStorage.getItem('sidebar-skin') === 'prime'
                  ? 'nav-link active'
                  : 'nav-link'
              }
              to={''}
            >
              Prime
            </Link>
            <Link
              onClick={sidebarSkin}
              className={
                localStorage.getItem('sidebar-skin') === 'dark'
                  ? 'nav-link active'
                  : 'nav-link'
              }
              to={''}
            >
              Dark
            </Link>
          </nav>
        </Dropdown.Menu>
      </Dropdown>

      {/* <Dropdown className="dropdown-notification ms-3 ms-xl-4" align="end">
        <Dropdown.Toggle as={CustomToggle}>
          <small>3</small>
          <i className="ri-notification-3-line"></i>
        </Dropdown.Toggle>
        <Dropdown.Menu className="mt-10-f me--10-f">
          <div className="dropdown-menu-header">
            <h6 className="dropdown-menu-title">Notifications</h6>
          </div>
          {NotificationList()}
          <div className="dropdown-menu-footer">
            <Link to="#">Show all Notifications</Link>
          </div>
        </Dropdown.Menu>
      </Dropdown> */}

      <Dropdown className="dropdown-profile ms-3 ms-xl-4" align="end">
        <Dropdown.Toggle as={CustomToggle}>
          <div className="avatar online">
            <img src={userAvatar} alt="" />
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu className="mt-10-f">
          <div className="dropdown-menu-body">
            <div className="avatar avatar-xl online mb-3">
              <img src={userAvatar} alt="" />
            </div>
            <AuthStateContext.Consumer>
              {(value) => {
                const { user } = value
                return (
                  <h5 className="mb-1 text-dark fw-semibold">{user?.email}</h5>
                )
              }}
            </AuthStateContext.Consumer>

            <nav className="nav">
              <Link to="">
                <i className="ri-edit-2-line"></i> Edit Profile
              </Link>
              <Link to="" onClick={onLogoutClick}>
                <i className="ri-logout-box-r-line"></i> Log Out
              </Link>
            </nav>
          </div>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  )
}
export default Header
