import { INetworkResponse } from './network'
import { IAuction, IAuctionResponseData } from './auction'
import { AllCurrencyEnum, AssetModelEnum, IProperty } from './asset'

export interface IInvestmentResponse extends INetworkResponse {
  _id: string
  user: string
  status: string
  asset: IProperty
  assetType: AssetModelEnum
  bidCount: Number
  auction: IAuctionResponseData
  amount: number
  certificate: {
    name: string
    value: string
    _id: string
  }
  unlockAt: string
  createdAt: string
  updatedAt: string
}

export interface IBidResponse extends INetworkResponse {
  _id: string
  user: string
  auction: {
    _id: string
    property: string
    minimumBid: number
    maximumBid: number
    minimumPrice: string
    status: string
    startTime: string
    endTime: string
    createdAt: string
    updatedAt: string
    totalBid: number
  }
  property: IAuctionResponseData['property']
  amount: number
  status: string
  createdAt: string
  updatedAt: string
  payment: {
    debit: string
    _id: string
  }
}

export interface IBid {
  _id?: string
  auction: IAuction
  assetType: AssetModelEnum
  asset: IProperty
  amount: number
  currency: AllCurrencyEnum
  status: BidStatusEnum
  payment?: BidPaymentType
  createdAt?: Date
  updatedAt?: Date
}
export enum BidStatusEnum {
  OPEN = 'OPEN',
  CLOSE = 'CLOSE',
  REFUNDED = 'REFUNDED',
}

export interface BidPaymentType {
  debit?: string
  credit?: string
}
