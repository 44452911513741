import * as UserService from '../../services/internal/user.service'
import { ActionType } from '../../interface/auth'
import { handleError } from '../../utils/error'

export const userActions = {
  LIST_USERS: 'LIST_USERS',
  FIND_USER: 'FIND_USER',
  BLOCK_USER: 'BLOCK_USER',
  UNBLOCK_USER: 'UNBLOCK_USER',
  USER_LOADING: 'USER_LOADING',
  LIST_INVESTMENTS: 'LIST_INVESTMENTS',
  FIND_INVESTMENT: 'FIND_INVESTMENT',
  LIST_BIDS: 'LIST_BIDS',
  FIND_BID: 'FIND_BID',
  LIST_USERS_DEPOSITS: 'LIST_USERS_DEPOSITS',
  LIST_USER_WITHDRAWALS: 'LIST_USER_WITHDRAWALS',
  LOADING: 'LOADING'
}

export const listUsers = async (): Promise<ActionType> => {
  try {
    const response = await UserService.list()
    return {
      type: userActions.LIST_USERS,
      payload: response.data
    }
  } catch (error: any) {
    throw new Error(handleError(error))
  }
}

export const findUser = async (id: string): Promise<ActionType> => {
  try {
    const response = await UserService.find(id)
    return {
      type: userActions.FIND_USER,
      payload: response.data
    }
  } catch (error: any) {
    throw new Error(handleError(error))
  }
}

export const blockUser = async (id: string): Promise<ActionType> => {
  try {
    const response = await UserService.block(id)
    return {
      type: userActions.BLOCK_USER,
      payload: response.data
    }
  } catch (error: any) {
    throw new Error(handleError(error))
  }
}

export const unblockUser = async (id: string): Promise<ActionType> => {
  try {
    const response = await UserService.unblock(id)
    return {
      type: userActions.UNBLOCK_USER,
      payload: response.data
    }
  } catch (error: any) {
    throw new Error(handleError(error))
  }
}

export const listUserInvestments = async (id: string): Promise<ActionType> => {
  try {
    const response = await UserService.listUserInvestments(id)
    return {
      type: userActions.LIST_INVESTMENTS,
      payload: response.data
    }
  } catch (error: any) {
    throw new Error(handleError(error))
  }
}

export const findUserInvestments = async (
  userId: string,
  investmentId: string
): Promise<ActionType> => {
  try {
    const response = await UserService.findUserInvestment(userId, investmentId)
    return {
      type: userActions.FIND_INVESTMENT,
      payload: response.data
    }
  } catch (error: any) {
    throw new Error(handleError(error))
  }
}

export const listUserBids = async (id: string): Promise<ActionType> => {
  try {
    const response = await UserService.listUserBids(id)
    return {
      type: userActions.LIST_BIDS,
      payload: response.data
    }
  } catch (error: any) {
    throw new Error(handleError(error))
  }
}

export const findUserBids = async (
  userId: string,
  bidId: string
): Promise<ActionType> => {
  try {
    const response = await UserService.findUserBid(userId, bidId)
    return {
      type: userActions.FIND_BID,
      payload: response.data
    }
  } catch (error: any) {
    throw new Error(handleError(error))
  }
}

export const listUserDeposits = async (id: string): Promise<ActionType> => {
  try {
    const response = await UserService.listUserDeposits(id)
    return {
      type: userActions.LIST_USERS_DEPOSITS,
      payload: response.data
    }
  } catch (error: any) {
    throw new Error(handleError(error))
  }
}

export const listUserWithdraws = async (id: string): Promise<ActionType> => {
  try {
    const response = await UserService.listUserWithdraws(id)
    return {
      type: userActions.LIST_USER_WITHDRAWALS,
      payload: response.data
    }
  } catch (error: any) {
    throw new Error(handleError(error))
  }
}

export const userLoadingAction = (payload: boolean) => {
  return {
    type: userActions.LOADING,
    payload
  }
}
